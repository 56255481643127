import React, { useState } from "react";
import { Form, ListGroup, Button, Modal, Row } from "react-bootstrap"; // Import necessary components
import LocationService from "../../services/LocationService";
import AdvertisementService from "../../services/AdvertisementService";

const AdActivationByCity = ({ showModal, handleCloseModal }) => {
    const [city, setCity] = useState(''); // Input for city
    const [locationSuggestions, setLocationSuggestions] = useState([]); // Suggestions for city
    const [showSuggestions, setShowSuggestions] = useState(false); // Toggle for showing suggestions
    const [location, setLocation] = useState('');

    // Hidden fields for state and country (internal use)
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');

    const [loading, setLoading] = useState(false); // For loading state
    const [error, setError] = useState(null); // For error handling
    const [success, setSuccess] = useState(null); // For success messages

    // Handle city input changes and fetch suggestions
    const onCityChange = async (e) => {
        const newCity = e.target.value;
        setLocation(newCity)

        if (newCity.length >= 3) {
            try {
                const response = await LocationService.getLocationByName(newCity);
                setLocationSuggestions(response);
                setShowSuggestions(true);
            } catch (error) {
                console.error('Error fetching location suggestions:', error);
            }
        } else {
            setShowSuggestions(false);
        }
    };

    // Handle location selection from the suggestions dropdown
    const handleLocationSelect = (selectedLocation) => {
        setLocation(selectedLocation.location);
        setCity(selectedLocation.city.name); // Set the city name in input
        setState(selectedLocation.state.name); // Automatically set state
        setCountry(selectedLocation.country.name); // Automatically set country
        setShowSuggestions(false); // Hide suggestions after selection        
    };

    // Activate advertisements by city
    const handleActivate = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            await AdvertisementService.activateAdvertisementByCity(city, state, country, token);
            setSuccess('Advertisements activated successfully');
        } catch (error) {
            setError('Failed to activate advertisements');
        } finally {
            setLoading(false);
        }
    };

    // Deactivate advertisements by city
    const handleDeactivate = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            await AdvertisementService.deactivateAdvertisementByCity(city, state, country, token);
            setSuccess('Advertisements deactivated successfully');
        } catch (error) {
            setError('Failed to deactivate advertisements');
        } finally {
            setLoading(false);
        }
    };

    const handleClose=()=>{
        setCity('');
        setLocationSuggestions([]);
        setShowSuggestions(false);
        setLoading(false);
        setError(null);
        setSuccess(null);
        setLocation('');
        handleCloseModal();
    };

    return (
        <Modal className='modal_style' show={showModal} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Activate/Deactivate Advertisements by City</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter city"
                            value={location}
                            onChange={onCityChange}
                            required
                        />
                        {showSuggestions && (
                            <ListGroup className="position-absolute w-90" style={{ zIndex: 1 }}>
                                {locationSuggestions.map((location, index) => (
                                    <ListGroup.Item key={index} action onClick={() => handleLocationSelect(location)}>
                                        {location.location} {/* Showing city suggestion */}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                    </Form.Group>

                    <Row className="justify-content-end modal_footer mt-3"> 
                        <Button
                            className="primary w-auto modal_btn mx-3"
                            onClick={handleActivate}
                            disabled={loading || !location}
                        >
                            {loading ? 'Activating...' : 'Activate'}
                        </Button>

                        <Button
                            className="w-auto"
                            variant="danger"
                            onClick={handleDeactivate}
                            disabled={loading || !location}
                        >
                            {loading ? 'Deactivating...' : 'Deactivate'}
                        </Button>
                    </Row>

                    {/* Success or error message */}
                    {success && <p className="text-success mt-3">{success}</p>}
                    {error && <p className="text-danger mt-3">{error}</p>}
            </Modal.Body>
        </Modal>
    );
}

export default AdActivationByCity;