import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Table from '../component/Tables/Table';
import CrmUserModal from '../component/FormModal/CrmUserModal';
import PlanModal from '../component/FormModal/PlanModal';
import CategoryModal from '../component/FormModal/CategoryModal';
import SubcategoryModal from '../component/FormModal/SubcategoryModal';
import ServiceModal from '../component/FormModal/ServiceModal';
import CustomerCsvModal from '../component/FormModal/CustomerCsvModal';
import CRMUserService from '../services/CRMUserService';
import PlanService from '../services/PlanService';
import CategoryService from '../services/CategoryService';
import SubcategoryService from '../services/Subcategoryservice';
import ServicesService from '../services/ServiceService';
import CustomerService from '../services/CustomerService';
import CSVResponseSummary from '../component/Common/CSVResponseSummary';
import UpdatePasswordModal from '../component/FormModal/UpdatePasswordModal';
import PaginationComponent from '../component/Common/PaginationComponent';
import AdvertisementService from '../services/AdvertisementService';
import ImageUploadModal from '../component/FormModal/ImageUploadModal';
import logo from '../login-logo.png';
import AdActivationByCity from '../component/FormModal/AdActivationByCity';
import SitemapService from '../services/SitemapService';

const AdminDashboard = () => {
    const { user, isLoggedIn, logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('crmusers');
    const [data, setData] = useState([]);
    const [showCrmUserModal, setShowCrmUserModal] = useState(false);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showSubcategoryModal, setShowSubcategoryModal] = useState(false);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [showCustomerCsvModal, setShowCustomerCsvModal] = useState(false);
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
    const [bulkresponse, setBulkResponse] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0)
    const [size, setSize] = useState(15);
    const [showBannerUploadModal, setShowBannerUploadModal] = useState(false);
    const [showAdActivationModal, setShowAdActivationModal] = useState(false);
    const [sitemapdownloading, setSitemapdownloading] = useState(false);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
        } else if (user) {
            const role = user.role.replace(/^\[|\]$/g, '');
            if (role !== 'ROLE_ADMIN') {
                logout();
                navigate('/unauthorised');
            }
        }
    }, [isLoggedIn, user, navigate]);

    useEffect(() => {
        fetchData(selectedOption);
        setCurrentPage(0)
    }, [selectedOption]);

    useEffect(() => {
        fetchData(selectedOption);
    }, [currentPage]);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const fetchData = async (option) => {
        setLoading(true);
        setData([]);
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token;
        try {
            let response;
            switch (option) {
                case 'crmusers':
                    response = await CRMUserService.getCRMUsersPages(currentPage, size, token);
                    setData(response.content);
                    setTotalPages(response.totalPages);
                    setTotalElements(response.totalElements);
                    break;
                case 'customers':
                    response = await CustomerService.getAllCustomersPages(currentPage, size, token);
                    setData(response.content);
                    setTotalPages(response.totalPages);
                    setTotalElements(response.totalElements);
                    break;
                case 'advertisements':
                    response = await AdvertisementService.getAdvertisements(currentPage, size, token);
                    setData(response.content);
                    setTotalPages(response.totalPages);
                    setTotalElements(response.totalElements);
                    break;
                case 'plans':
                    response = await PlanService.getAllPlans(token);
                    setData(response);
                    setTotalPages(0);
                    setTotalElements(0);
                    break;
                case 'categories':
                    response = await CategoryService.getAllCategories(token);
                    setData(response);
                    setTotalPages(0);
                    setTotalElements(0);
                    break;
                case 'subcategories':
                    response = await SubcategoryService.getAllSubcategories(token);
                    setData(response);
                    setTotalPages(0);
                    setTotalElements(0);
                    break;
                case 'services':
                    response = await ServicesService.getAllServicesPages(currentPage, size, token);
                    setData(response.content);
                    setTotalPages(response.totalPages);
                    setTotalElements(response.totalElements);
                    break;
                default:
                    console.error("Unknown option : " + option);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const handleCrmUserSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            if (data.id) {
                const response = await CRMUserService.updateCRMUser(data.id, data, token);
                alert(response);
            } else {
                const response = await CRMUserService.createCRMUser(data, token);
                alert(response);
            }
            fetchData(selectedOption);
        } catch (error) {
            alert(error.message);
            console.error('Error processing CRM User:', error.message);
        } finally {
            setFormLoading(false);
            setShowCrmUserModal(false);
        }
    };

    const handleUpdatePasswordSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            const { crmUserId, oldPassword, newPassword } = data;
            const response = await CRMUserService.updatePassword(crmUserId, { oldPassword, newPassword }, token);
            alert(response);
        } catch (error) {
            console.error('Error updating password::', error.message);
        } finally {
            setFormLoading(false);
            setShowUpdatePasswordModal(false);
        }
    };

    const handlePlanSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            const planDTO = {
                name: data.name,
                price: data.price,
                description: data.description,
            };
            const image = data.file;
            if (data.id) {
                const response = await PlanService.updatePlan(data.id, planDTO, image, token);
                alert(response);
            } else {
                const response = await PlanService.createPlan(planDTO, image, token);
                alert(response);
            }
            fetchData(selectedOption);
        } catch (error) {
            console.error('Error processing Plan:', error.message);
        } finally {
            setFormLoading(false);
            setShowPlanModal(false);
        }
    };

    const handleCategorySubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            let response;
            if (data.id) {
                response = await CategoryService.updateCategory(data.id, data, token);
                alert(response);
            } else {
                response = await CategoryService.createCategory(data, token);
                alert(response);
            }
            fetchData(selectedOption);
        } catch (error) {
            console.error('Error processing Category:', error.message);
        } finally {
            setFormLoading(false);
            setShowCategoryModal(false);
        }
    };

    const handleSubcategorySubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            let response;
            if (data.id) {
                response = await SubcategoryService.updateSubcategory(data.id, data, token);
                alert(response);
            } else {
                response = await SubcategoryService.createSubcategory(data, token);
                alert(response);
            }
            fetchData(selectedOption);
        } catch (error) {
            console.error('Error processing Subcategory:', error.message);
        } finally {
            setFormLoading(false);
            setShowSubcategoryModal(false);
        }
    };

    const handleServiceSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            let response;
            if (data.id) {
                response = await ServicesService.updateService(data.id, data, token);
                alert(response);
            } else {
                response = await ServicesService.createService(data, token);
                alert(response);
            }
            fetchData(selectedOption);
        } catch (error) {
            console.error('Error processing Service:', error.message);
        } finally {
            setFormLoading(false);
            setShowServiceModal(false);
        }
    };

    const handleCustomerCsvSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            const file = data.file;
            const fileType = data.fileType;
            let response;
            if (fileType === 'customer') {
                response = await CustomerService.bulkCreateCustomers(file, token);
            } else if (fileType === 'advertisement') {
                response = await AdvertisementService.bulkCreateAdvertisements(file, token);
            } else {
                throw new Error('Unsupported file type');
            }
            setBulkResponse(response);
        } catch (error) {
            console.error('Error occured to add csv:', error.message);
        } finally {
            setFormLoading(false)
            setShowCustomerCsvModal(false);
        }
    };

    const handleDelete = async (id) => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token;
        try {
            let response;
            switch (selectedOption) {
                case 'crmusers':
                    response = await CRMUserService.deleteCRMUser(id, token);
                    break;
                // case 'customers':
                //     response = await CustomerService.deleteCustomer(id, token);
                //     break;
                case 'plans':
                    response = await PlanService.deletePlan(id, token);
                    break;
                case 'services':
                    response = await ServicesService.deleteService(id, token);
                    break;
                case 'categories':
                    response = await CategoryService.deleteCategory(id, token);
                    break;
                case 'subcategories':
                    response = await SubcategoryService.deleteSubcategory(id, token);
                    break;
                // case 'advertisements':
                //     response = await AdvertisementService.deleteAdvertisement(id, token);
                //     break;
                default:
                    throw new Error(`No service configured for option: ${selectedOption}`);
            }
            // Refresh data after deletion
            fetchData(selectedOption);
            alert('deleted successfully');
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleEditModal = (item) => {
        setSelectedItem(item);
        switch (selectedOption) {
            case 'crmusers':
                setShowCrmUserModal(true);
                break;
            case 'plans':
                setShowPlanModal(true);
                break;
            case 'categories':
                setShowCategoryModal(true);
                break;
            case 'subcategories':
                setShowSubcategoryModal(true);
                break;
            case 'services':
                setShowServiceModal(true);
                break;
            default:
                console.error("Unknown option: " + selectedOption);
        }
    };

    const handleDownloadSitemap = async () => {
        const userConfirmed = window.confirm("Do you really want to download the sitemap?");
        if (userConfirmed) {
            setSitemapdownloading(true);
            try {
                const token = user.token;
                await SitemapService.downloadSitemapXml(token);
            } catch (error) {
                console.error('Failed to download sitemap:', error);
            } finally {
                setSitemapdownloading(false);
            }
        }
    };

    const handleCollapse = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    const options = [
        { key: 'crmusers', label: 'CRM Users', icon: 'fas fa-users' },
        { key: 'customers', label: 'Customers', icon: 'fas fa-store' },
        { key: 'advertisements', label: 'Advertisements', icon: 'fas fa-ad' },
        { key: 'plans', label: 'Plans', icon: 'fas fa-scroll' },
        { key: 'categories', label: 'Categories', icon: 'fas fa-cube' },
        { key: 'subcategories', label: 'Sub-categories', icon: 'fas fa-cubes' },
        { key: 'services', label: 'Services', icon: 'fas fa-cog' },
    ];

    const columnsMap = {
        crmusers: ['id', 'name', 'designation', 'email', 'phone'],
        customers: ['id', 'companyName', 'address', 'city', 'state', 'country', 'source'],
        advertisements: ['id', 'active', 'companyName', 'city', 'state', 'country'],
        plans: ['id', 'name', 'price', 'imageName'],
        categories: ['id', 'name'],
        subcategories: ['id', 'name'],
        services: ['id', 'name'],
    };

    return (<>

        <section className="manage_dashoard_nav">
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <a className="navbar-brand">
                            <h3><img src={logo} className='img-fluid' /> Admin Dashboard</h3>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" role="button" id="addDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Add Data
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="addDropdown">
                                        <button className="dropdown-item" onClick={() => { setShowCrmUserModal(true); setSelectedItem(null); }}>CRM User</button>
                                        <button className="dropdown-item" onClick={() => { setShowPlanModal(true); setSelectedItem(null); }}>Plan</button>
                                        <button className="dropdown-item" onClick={() => { setShowCategoryModal(true); setSelectedItem(null); }}>Category</button>
                                        <button className="dropdown-item" onClick={() => { setShowSubcategoryModal(true); setSelectedItem(null); }}>Sub Category</button>
                                        <button className="dropdown-item" onClick={() => { setShowServiceModal(true); setSelectedItem(null); }}>Services</button>
                                        <button className="dropdown-item" onClick={() => setShowCustomerCsvModal(true)}>Upload CSV</button>
                                        <button className="dropdown-item" onClick={() => setShowUpdatePasswordModal(true)}>Update Password</button>
                                        <button className="dropdown-item" onClick={() => setShowBannerUploadModal(true)}>Upload Banner</button>
                                        <button className="dropdown-item" onClick={() => setShowAdActivationModal(true)}>Show/Hide Cities</button>
                                    </div>
                                </li>
                            </ul>
                            <button className="btn btn-theme" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </button>
                        </div>
                    </nav>

                </div>
            </div>
        </section>

        <section className="manage_dashoard_content pb-0 vh-100">
            <div className="container">
                <div className="row justify-content-end">
                <div className={`sidebar_collapsed ${sidebarCollapsed ? 'collapsed_minimized' : ''} px-0`}>
                        <div className='menu-title position-relative'>
                            <h4>Main Menu</h4>
                            <button className="btn collapse-btn" onClick={handleCollapse}>
                           {sidebarCollapsed ? <i className="fas fa-chevron-circle-right"></i> : <i className="fas fa-chevron-circle-left"></i>}
                            </button>
                        </div>
                        <div className="left_sidebar admin_sidebar">
                            <aside className="sidebar">
                                <ul className="list-group text-start bg-white">
                                    {options.map(option => (
                                        <li
                                            key={option.key}
                                            className={`list-group-item ${selectedOption === option.key ? 'bg-warning' : ''}`}
                                            onClick={() => setSelectedOption(option.key)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className={option.icon}></i> <span className='label_list'>{option.label}</span>
                                        </li>
                                    ))}
                                    <li
                                     className='list-group-item'>
                                        <button className="dropdown-item" onClick={handleDownloadSitemap} disabled={sitemapdownloading}><span className='label_list'>{sitemapdownloading ? 'Generating...' : 'Download Sitemap'}</span> <i className="fas fa-download"></i></button>
                                    </li>
                                </ul>
                            </aside>
                        </div>
                    </div>

                    <div className="content-bar">
                        <div className="dashboard-content-left">
                        <h3 className="text-start admin_table_h3">{options.find(option => option.key === selectedOption)?.label} {totalElements > 1 && totalElements}</h3>
                            <main className="main-table-content admin_table_content">
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center vh-100">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <span className="ms-3">Loading data...</span>
                                    </div>
                                ) : (<>

                                    <Table
                                        data={data}
                                        columnsToShow={columnsMap[selectedOption]}
                                        onDelete={handleDelete}
                                        onEdit={handleEditModal} />

                                    <CSVResponseSummary response={bulkresponse} onClose={() => { setBulkResponse(null) }} />

                                    {totalPages > 1 && (
                                        <PaginationComponent
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        />
                                    )}
                                </>
                                )}

                            </main>
                        </div>
                    </div>

                    <CrmUserModal
                        show={showCrmUserModal}
                        handleClose={() => setShowCrmUserModal(false)}
                        handleSubmit={handleCrmUserSubmit}
                        user={selectedItem}
                        response={formLoading}
                    />

                    <PlanModal
                        show={showPlanModal}
                        handleClose={() => setShowPlanModal(false)}
                        handleSubmit={handlePlanSubmit}
                        plan={selectedItem}
                        response={formLoading}
                    />

                    <CategoryModal
                        show={showCategoryModal}
                        handleClose={() => setShowCategoryModal(false)}
                        handleSubmit={handleCategorySubmit}
                        category={selectedItem}
                        response={formLoading}
                    />

                    <SubcategoryModal
                        show={showSubcategoryModal}
                        handleClose={() => setShowSubcategoryModal(false)}
                        handleSubmit={handleSubcategorySubmit}
                        subcategory={selectedItem}
                        response={formLoading}
                    />

                    <ServiceModal
                        show={showServiceModal}
                        handleClose={() => setShowServiceModal(false)}
                        handleSubmit={handleServiceSubmit}
                        service={selectedItem}
                        response={formLoading}
                    />

                    <CustomerCsvModal
                        show={showCustomerCsvModal}
                        handleClose={() => setShowCustomerCsvModal(false)}
                        handleSubmit={handleCustomerCsvSubmit}
                        response={formLoading}
                    />

                    <UpdatePasswordModal
                        show={showUpdatePasswordModal}
                        handleClose={() => setShowUpdatePasswordModal(false)}
                        handleSubmit={handleUpdatePasswordSubmit}
                        response={formLoading}
                    />

                    <ImageUploadModal
                        show={showBannerUploadModal}
                        handleClose={() => setShowBannerUploadModal(false)}
                    />

                    <AdActivationByCity
                        showModal={showAdActivationModal}
                        handleCloseModal={()=>setShowAdActivationModal(false)}
                    />
                </div>
            </div>
        </section>
    </>
    );
};

export default AdminDashboard;