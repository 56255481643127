import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/follow-up";

const createFollowUp = async (followUpDTO, token) => {
    try {
        const response = await axios.post(API_URL+'/create', followUpDTO, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to create follow-up');
    }
};

const getFollowUp = async (executiveId, date = null, token) => {
    try {
        // Prepare the params object
        const params = {
            executiveId: executiveId
        };

        // Only add the date if it's not null or undefined
        if (date) {
            params.date = date;
        }

        // Make the request
        const response = await axios.get(`${API_URL}/bydate`, {
            params: params,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get follow-up');
    }
};

const followUpComplete = async (followUpId, token) => {
    try {
        const response = await axios.put(`${API_URL}/complete/${followUpId}`, { }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to create follow-up');
    }
};

const followUpDelete = async (followUpId, token) => {
    try {
        const response = await axios.delete(`${API_URL}/delete/${followUpId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to create follow-up');
    }
};

const FollowUpService = {
    createFollowUp,
    getFollowUp,
    followUpComplete,
    followUpDelete
}

export default FollowUpService;