import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/plans";

const getAllPlans = async (token) => {
    try {
        const response = await axios.get(API_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to getting Plan');
    }
};

const getPlanById = async (id, token) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to getting Plan');
    }
};

const createPlan = async (planDTO, image, token) => {
    const formData = new FormData();
    formData.append('plan', new Blob([JSON.stringify(planDTO)], { type: 'application/json' }));
    formData.append('image', image);
    try {
        const response = await axios.post(API_URL, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to create Plan');
    }
};

const updatePlan = async (id, planDTO, image, token) => {
    const formData = new FormData();
    formData.append('plan', new Blob([JSON.stringify(planDTO)], { type: "application/json" }));
    if (image) {
        formData.append('image', image);
    }
    try {
        const response = await axios.put(`${API_URL}/${id}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to updating Plan');
    }
};

const deletePlan = async (id, token) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delete Plan');
    }
};

const PlanService = {
    getAllPlans,
    getPlanById,
    createPlan,
    updatePlan,
    deletePlan
};

export default PlanService;
