import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function ServicesDropdown({ services, selectedServices, setSelectedServices }) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (selectedServices.includes(value)) {
            setSelectedServices(selectedServices.filter(service => service !== value));
        } else {
            setSelectedServices([...selectedServices, value]);
        }
    };

    // Filter services based on search term
    const filteredServices = services.filter(service =>
        service.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort services: selected items on top and alphabetically
    const sortedServices = [...filteredServices].sort((a, b) => {
        const aSelected = selectedServices.includes(a.id.toString());
        const bSelected = selectedServices.includes(b.id.toString());

        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;

        // If both are either selected or not selected, sort alphabetically
        return a.name.localeCompare(b.name);
    });

    return (
        <div>
            <Form.Control
                type="text"
                placeholder="Search services..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px' }}
            />
            <div className='new_ad_services'>
                {sortedServices.map(service => (
                    <div key={service.id} className="custom-checkbox">
                        <input
                            type="checkbox"
                            id={`service-${service.id}`}
                            value={service.id}
                            checked={selectedServices.includes(service.id.toString())}
                            onChange={handleCheckboxChange}
                            style={{ marginRight: '5px' }}
                        />
                        <label
                            htmlFor={`service-${service.id}`}
                            style={{ cursor: 'pointer' }}
                        >
                            {service.name}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServicesDropdown;
