import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, InputGroup, FormControl } from 'react-bootstrap';
import CRMUserService from '../../services/CRMUserService';
import CustomerService from '../../services/CustomerService';

const CustomerAssignModal = ({ show, handleClose, handleSubmit, response }) => {
    const [executives, setExecutives] = useState([]);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState('');
    const [manualExecutiveId, setManualExecutiveId] = useState('');
    const [selectedCitiesList, setSelectedCitiesList] = useState([]);
    const [searchCity, setSearchCity] = useState('');

    useEffect(() => {
        if (show) {
            fetchExecutives();
            fetchCities();
        }
    }, [show]);

    useEffect(() => {
        if (selectedExecutive || manualExecutiveId) {
            const executiveId = selectedExecutive || manualExecutiveId;
            const executive = executives.find(exec => exec.id === Number(executiveId));
            if (executive && executive.cities) {
                setSelectedCitiesList(executive.cities);
            } else {
                setSelectedCitiesList([]);
            }
        } else {
            setSelectedCitiesList([]);
        }
    }, [selectedExecutive, manualExecutiveId, executives]);

    useEffect(() => {
        if (cities.length > 0) {
            const filtered = cities.filter(city => city.toLowerCase().includes(searchCity.toLowerCase()));
            setFilteredCities(filtered);
        }
    }, [searchCity, cities]);

    const fetchExecutives = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            const executivesData = await CRMUserService.getCRMUserByRole('SALES_EXECUTIVE', token);
            setExecutives(executivesData);
        } catch (error) {
            console.error('Error fetching executives:', error.message);
        }
    };

    const fetchCities = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            const citiesData = await CustomerService.getCustomerCities(token);
            setCities(citiesData);
            setFilteredCities(citiesData); // Initialize filteredCities
        } catch (error) {
            console.error('Error fetching cities:', error.message);
        }
    };

    const handleExecutiveChange = (e) => {
        setSelectedExecutive(e.target.value);
        setManualExecutiveId('');
    };

    const handleManualIdChange = (e) => {
        setManualExecutiveId(e.target.value);
        setSelectedExecutive('');
    };

    const handleSearchChange = (e) => {
        setSearchCity(e.target.value);
    };

    const handleCityChange = (city) => {
        setSelectedCitiesList(prevState =>
            prevState.includes(city) ? prevState.filter(c => c !== city) : [...prevState, city]
        );
    };

    const handleAddCities = () => {
        handleSubmit({
            id: selectedExecutive || manualExecutiveId,
            cities: selectedCitiesList
        });
        handleCloseModal();
    };

    const handleRemoveCities = () => {
        handleSubmit({
            id: selectedExecutive || manualExecutiveId,
            cities: selectedCitiesList,
            remove: true // Indicate that cities should be removed
        });
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setSelectedExecutive('');
        setManualExecutiveId('');
        setSelectedCitiesList([]);
        setSearchCity('');
        handleClose();
    };

    return (
        <Modal className='modal_style' show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Cities to Executive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Executive</Form.Label>
                        <InputGroup>
                            <Form.Control
                                as="select"
                                value={selectedExecutive}
                                onChange={handleExecutiveChange}
                            >
                                <option value="">Select executive</option>
                                {executives.map((executive) => (
                                    <option key={executive.id} value={executive.id}>
                                        {executive.name}
                                    </option>
                                ))}
                            </Form.Control>
                            <FormControl
                                placeholder="Or enter executive ID"
                                value={manualExecutiveId}
                                onChange={handleManualIdChange}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Search Cities</Form.Label>
                        <FormControl
                            type="text"
                            placeholder="Search cities"
                            value={searchCity}
                            onChange={handleSearchChange}
                        />
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Select Cities</Form.Label>
                        <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ddd', padding: '10px' }}>
                            {filteredCities.map((city) => (
                                <Form.Check
                                    key={city}
                                    type="checkbox"
                                    id={`city-${city}`}
                                    label={city}
                                    checked={selectedCitiesList.includes(city)}
                                    onChange={() => handleCityChange(city)}
                                />
                            ))}
                        </div>
                    </Form.Group>
                    <Row className="justify-content-end modal_footer mt-3">
                        <Button className="primary w-auto modal_btn mx-3" onClick={handleAddCities} disabled={response}>
                            Add Cities
                        </Button>
                        <Button variant="danger" className="w-auto" onClick={handleRemoveCities} disabled={response}>
                            Remove Cities
                        </Button>
                    </Row>

                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CustomerAssignModal;
