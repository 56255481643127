import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import AdvertisementService from '../../services/AdvertisementService';

const PositionModal = ({ show, handleClose, ad }) => {
    const [position, setPosition] = useState(ad?.position ?? '');
    const [occupiedPositions, setOccupiedPositions] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setsuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setPosition(ad?.position ?? '');
            fetchOccupiedPositions();
        }
    }, [show]);

    const fetchOccupiedPositions = async () => {
        try {
            const response = await AdvertisementService.getOccupiedPositions(ad.subcategoryIds, ad.city, ad.state)
            setOccupiedPositions(response);
        } catch (error) {
            console.error('Error fetching occupied positions:', error.message);
            setError('Failed to fetch occupied positions.');
        }
    };

    const handleSave = async () => {
        setLoading(true);
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token
        try {
            const response = await AdvertisementService.assignPosition(ad.id, position, token)
            setsuccessMessage(response);
        } catch (error) {
            console.error('Error updating position:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const onClose = () => {
        setOccupiedPositions([]);
        setPosition('');
        setError('');
        setsuccessMessage('');
        handleClose();
    };

    return (
        <Modal className='modal_style' show={show} onHide={onClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Manage Advertisement Position</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                <Form.Group>
                    <Form.Label>Current Position</Form.Label>
                    <Form.Control
                        type="text"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                    />
                </Form.Group> 
                <div className='manage_position_text'>
                    <h5>Occupied Positions</h5>
                    {occupiedPositions.length > 0 ? (
                        <ul>
                            {occupiedPositions.map(pos => (
                                <li key={pos}>Position {pos} is occupied</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No occupied positions found in this AD city and category.</p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary modal_btn" onClick={handleSave} disabled={loading}>
                    {loading ? (
                        <>
                            <Spinner animation="border" size="sm" /> Saving...
                        </>
                    ) : (
                        "Save Position"
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PositionModal;
