const API_URL = 'https://nationalusa.net/api/';
const API_KEY= 'a1nm2o55l5';

const getLocationByName = async (newLocation) => {
    try {
        const response = await fetch(`${API_URL}getlocationlistbyname?apikey=${API_KEY}&name=${newLocation}`);
        if (!response.ok) {
            throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        return data.response;
    } catch (error) {
        throw new Error(error.message || 'Failed to get location');
    }
};

const LocationService = {
    getLocationByName
};

export default LocationService;
