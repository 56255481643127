import React, { useContext, useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import CustomerService from "../services/CustomerService";
import ServiceModal from "../component/FormModal/ServiceModal";
import ServicesService from "../services/ServiceService";
import CustomerStatusUpdate from "../component/Common/CustomerStatusUpdate";
import PaginationComponent from "../component/Common/PaginationComponent";
import CustomerTable from "../component/Tables/CustomerTable";
import StatusButtons from "../component/Common/StatusButtons";
import CustomerModal from "../component/FormModal/CustomerModal";
import CustomerAssignModal from "../component/FormModal/CustomerAssignModal";
import CRMUserService from "../services/CRMUserService";
import AdvertisementModal from "../component/FormModal/AdvertisementModal";
import AdvertisementService from "../services/AdvertisementService";
import ViewAdvertisementsModal from "../component/Common/ViewAdvertisementsModal";
import logo from '../login-logo.png';
import ViewFollowUps from "../component/Common/ViewFollowUps";
import FollowUpService from "../services/FollowUpService";
import AdActivationByCity from "../component/FormModal/AdActivationByCity";

const ManagerDashboard = () => {
    const { user, isLoggedIn, logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const navigate = useNavigate();
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [showAdvertisementModal, setShowAdvertisementModal] = useState(false);
    const [showCustomerADs, setShowCustomerADs] = useState(false);
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showCustomerAssignModal, setShowCustomerAssignModal] = useState(false);
    const [loginUserDetails, setLoginUserDetils] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [customersFilter, setCustomersFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0)
    const [size, setSize] = useState(15);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('');
    const [showFollowUps, setShowFollowUps] = useState(false);
    const [followUps, setFollowUps] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [showAdActivationModal, setShowAdActivationModal] = useState(false);

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
        } else if (user) {
            const role = user.role.replace(/^\[|\]$/g, '');
            if (role !== 'ROLE_SALES_MANAGER' && role !== 'ROLE_SALES_EXECUTIVE') {
                navigate('/unauthorised');
            } else {
                fetchLoginUser();
            }
        }
    }, [isLoggedIn, user, navigate]);

    useEffect(() => {
        if (loginUserDetails) {
            fetchCustomers();
        }
    }, [searchTerm, searchBy, currentPage, customersFilter, loginUserDetails]);

    const fetchLoginUser = async () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token;
        try {
            const response = await CRMUserService.getLogInUser(token);
            setLoginUserDetils(response);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoginUserDetils(null)
            logout();
            navigate('/login');
        }
    }

    const fetchCustomers = async () => {
        setLoading(true);
        setCustomers([]);
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token;
        const role = storedUser.role.replace(/^\[|\]$/g, '');
        try {
            let response;
            if (searchTerm && searchBy) {
                response = await CustomerService.searchCustomers(searchBy, searchTerm, currentPage, size, token);
            } else {
                if (role === 'ROLE_SALES_MANAGER') {
                    response = await CustomerService.getAllCustomersByStatus(customersFilter, currentPage, size, token);
                } else if (role === 'ROLE_SALES_EXECUTIVE') {
                    response = await CustomerService.getCustomersForExecutive(loginUserDetails.id, customersFilter, currentPage, size, token);
                }
            }
            setCustomers(response.content);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Error fetching data:', error);
            setCustomers([]);
        } finally {
            setLoading(false);
            setSelectedCustomerId(null);
        }
    }

        // Fetch follow-ups function
        const fetchFollowUps = async () => {            
            try {
                const token = JSON.parse(localStorage.getItem("user")).token;
                if (loginUserDetails) {
                    const data = await FollowUpService.getFollowUp(loginUserDetails.id, null ,token);
                    setFollowUps(data);
                }
            } catch (error) {
                console.error("Failed to fetch follow-ups:", error);
            }
        };
    
        // Effect to fetch follow-ups when component mounts or loginUserDetails changes
        useEffect(() => {
            if (loginUserDetails) {
                fetchFollowUps();
            }
        }, [loginUserDetails]);
    
        // Effect to check for alerts
        useEffect(() => {
            const intervalId = setInterval(() => {
                const now = new Date();
                const newAlerts = [];
    
                followUps.forEach((followUp) => {
                    const followUpTime = new Date(followUp.followUpDate);
                    const timeDifference = Math.abs(followUpTime - now) / 1000; // difference in seconds
                    
                    // Trigger alert if the follow-up time is due and not completed
                    if (timeDifference > 0 && !followUp.completed) {     
                        newAlerts.push(followUp);
                    }
                });
    
                setAlerts(newAlerts);
            }, 30000); // Check every 30 seconds
    
            return () => clearInterval(intervalId); // Cleanup interval on unmount
        }, [followUps]); // Dependency on followUps
    
        // Handle follow-up completion
        const handleFollowUpComplete = async (followUpId) => {
            try {
                const token = JSON.parse(localStorage.getItem('user')).token; 
                const response = await FollowUpService.followUpComplete(followUpId, token);
                alert(response);
                 // Update local state to remove the alert
                setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== followUpId));
                // After completing, fetch follow-ups again to get the latest list
                fetchFollowUps();
            } catch (error) {
                console.error("Failed to mark follow-up as complete:", error.message);
            }
        };

    const handleFollowUpAdded = (response) => {
        if (response) {
            alert(response);

            fetchFollowUps();
        }
    };

        const handleFollowUpUpdate = (response) => {
            if (response) {
                fetchFollowUps();
            }
        };

    const handleLogout = () => {
        setLoginUserDetils(null)
        logout();
        navigate('/login');
    };

    const handleServiceSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            const response = await ServicesService.createService(data, token);
            alert(response);
        } catch (error) {
            console.error('Error processing Service:', error.message);
        } finally {
            setFormLoading(false);
            setShowServiceModal(false);
        }
    };

    const handleCustomerSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            if (data.id) {
                const response = await CustomerService.updateCustomer(data.id, data, token);
                alert(response);
            } else {
                const response = await CustomerService.createCustomer(data);
                alert(response);
            }
            fetchCustomers();
        } catch (error) {
            console.error('Error processing customer:', error.message);
        } finally {
            setFormLoading(false);
            setShowCustomerModal(false);
        }
    };

    const handleAssignSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            if (data.remove) {
                const response = await CRMUserService.removeCities(data, token);
                alert(response);
            } else {
                const response = await CRMUserService.addCities(data, token);
                alert(response);
            }
        } catch (error) {
            console.error('Error assigning customer:', error.message);
        } finally {
            setFormLoading(false);
            setShowCustomerAssignModal(false);
        }
    };

    const handleAdvertisementSubmit = async (data) => {
        setFormLoading(true);
        try {
            const token = user.token;
            const response = await AdvertisementService.createAdvertisement(data, token);
            alert(response);
        } catch (error) {
            console.error('Error processing AD:', error.message);
        } finally {
            setFormLoading(false);
            setShowAdvertisementModal(false);
        }
    };

    const viewAdvertisements = (customerId) => {
        setSelectedCustomerId(customerId);
        setShowCustomerADs(true);
    };

    const addAdvertisement = (customer) => {
        setSelectedCustomerId(customer.id);
        setSelectedCustomer(customer);
        setShowAdvertisementModal(true);
    };

    const editCustomer = (customer) => {
        setSelectedCustomer(customer);
        setShowCustomerModal(true);
    };

    const deleteCustomer = async (customerId) => {
        if (window.confirm('Are you sure you want to delete this customer?')) {
            try {
                const token = user.token;
                const response = await CustomerService.deleteCustomer(customerId, token);
                alert(response);
                fetchCustomers();
            } catch (error) {
                console.error('Error processing customer:', error.message);
            }
        };
    };

    const handleSearch = (searchTerm, searchBy) => {
        setSearchTerm(searchTerm);
        setSearchBy(searchBy);
        setCurrentPage(0);
    };

    const handleFilter = (status) => {
        setCustomersFilter(status)
        setSearchTerm('');
        setSearchBy('');
        setCurrentPage(0);
    };

    return (<>
        {/* ---------------Dashborad Navigation Start----------------- */}

        <div className="followup_outer">
            {alerts.length > 0 && (
                <div className="followup_popup_msg"> 
                    {alerts.map((alert) => (
                        <div key={alert.id} className="follow_msg_box"> 
                            <Alert variant="warning text-left">
                                Message : <strong className="text-warning">{alert.message}</strong><br />
                                <strong>{alert.customeName}</strong> <br /><strong>{alert.customerPhone}</strong> -{" "}
                                {new Date(alert.followUpDate).toLocaleString()}
                            </Alert> 

                            <Button
                                variant="link"
                                className="follow_done_btn"
                                onClick={() => handleFollowUpComplete(alert.id)}
                                aria-label="Done"
                            >
                                <i className="fa-solid fa-times"></i>
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </div>


        <section className="manage_dashoard_nav">
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <a className="navbar-brand">
                            {/* {loginUserDetails ? loginUserDetails.name : 'ORS CRM'}  */}
                            <h3><img src={logo} className='img-fluid' /> ORS - {loginUserDetails ? loginUserDetails.name : 'CRM'}</h3>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <button className="btn btn-link nav-link" onClick={() => setShowFollowUps(true)}>Today Follow-Ups</button>
                                </li>
                                {loginUserDetails && loginUserDetails.role === 'SALES_MANAGER' ? (
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" role="button" id="addDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Actions
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="addDropdown">
                                            <button className="dropdown-item" onClick={() => { setShowCustomerModal(true); setSelectedCustomer(null); }}>Add Customer</button>
                                            <button className="dropdown-item" onClick={() => setShowServiceModal(true)}>Add Services</button>
                                            <button className="dropdown-item" onClick={() => setShowCustomerAssignModal(true)}>Assing Cities</button>
                                            <button className="dropdown-item" onClick={() => setShowAdActivationModal(true)}>Show/Hide Cities</button>
                                        </div>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <button className="btn btn-link nav-link" onClick={() => { setShowCustomerModal(true); setSelectedCustomer(null); }}><i className="fa-solid fa-plus"></i> Add Customer</button>
                                    </li>
                                )}
                            </ul>

                            <button className="btn btn-theme" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </button>
                        </div>
                    </nav>


                </div>
            </div>
        </section>

        {/* ---------------Dashborad Navigation End----------------- */}


        <section className="manage_dashoard_content">  
            <div className="container">
                <div className="row">
                    <div className="col-md-9 px-0">
                        <div className="dashboard-content-left">
                            <main className="main-table-content">
                                <StatusButtons setShowCustomers={handleFilter} />
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center vh-100">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <span className="ms-3">Loading data...</span>
                                    </div>
                                ) : (<>

                                    <CustomerTable
                                        customers={customers}
                                        viewAdvertisements={viewAdvertisements}
                                        addAdvertisement={addAdvertisement}
                                        editCustomer={editCustomer}
                                        deleteCustomer={deleteCustomer}
                                        handleSearch={handleSearch}
                                        customerId={(id) => setSelectedCustomerId(id)}
                                    />

                                    {totalPages > 1 && (
                                        <PaginationComponent
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        />
                                    )}
                                </>
                                )}

                            </main>
                        </div>
                    </div>

                    <div className="col-md-3 px-0">
                        <div className="right_notes">
                            <aside className="right-side">
                                {loginUserDetails && (
                                    <CustomerStatusUpdate
                                        executiveId={loginUserDetails.id}
                                        customerId={selectedCustomerId}
                                        executiveName={loginUserDetails.name}
                                        handleFollowUpAdded={handleFollowUpAdded}
                                    />
                                )}
                            </aside>
                        </div>
                    </div>

                    {loginUserDetails && (<CustomerModal
                        show={showCustomerModal}
                        handleClose={() => setShowCustomerModal(false)}
                        handleSubmit={handleCustomerSubmit}
                        customer={selectedCustomer}
                        executiveName={loginUserDetails.name}
                        response={formLoading}
                    />
                    )}

                    <ServiceModal
                        show={showServiceModal}
                        handleClose={() => setShowServiceModal(false)}
                        handleSubmit={handleServiceSubmit}
                        response={formLoading}
                    />

                    <CustomerAssignModal
                        show={showCustomerAssignModal}
                        handleClose={() => setShowCustomerAssignModal(false)}
                        handleSubmit={handleAssignSubmit}
                        response={formLoading}
                    />

                    <AdvertisementModal
                        show={showAdvertisementModal}
                        handleClose={() => setShowAdvertisementModal(false)}
                        handleSubmit={handleAdvertisementSubmit}
                        customer={selectedCustomer}
                        response={formLoading}
                    />

                    <ViewAdvertisementsModal
                        customerId={selectedCustomerId}
                        show={showCustomerADs}
                        onClose={() => setShowCustomerADs(false)}
                    />

                    {loginUserDetails && (
                        <ViewFollowUps
                        show={showFollowUps}
                        executiveId={loginUserDetails.id}
                        onClose={() => setShowFollowUps(false)}
                        handleFollowUpUpdate={handleFollowUpUpdate}
                        />
                    )}

                    <AdActivationByCity 
                        showModal={showAdActivationModal} 
                        handleCloseModal={()=>setShowAdActivationModal(false)} 
                    />

                </div>
            </div>
        </section>
    </>

    );
}

export default ManagerDashboard