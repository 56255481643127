import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AdvertisementCard from './AdvertisementCard';
import AdvertisementService from '../../services/AdvertisementService';
import AdvertisementModal from '../FormModal/AdvertisementModal';
import ImageUploadModal from '../FormModal/ImageUploadModal';
import PositionModal from '../FormModal/PositionModal';
import ServicesService from '../../services/ServiceService';

function ViewAdvertisementsModal({ customerId, show, onClose }) {
    const [advertisements, setAdvertisements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [showAdvertisementModal, setShowAdvertisementModal] = useState(false);
    const [showImageUploadModal, setShowImageUploadModal] = useState(false);
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [selectedAd, setSelectedAd] = useState(null);
    const [adId, setAdId] = useState(null);
    const [servicesMap, setServicesMap] = useState({});

    useEffect(() => {
        if (show) {
            // Fetch advertisements for the given customer ID
            fetchAdvertisements();
        }
    }, [show]);

    const fetchAdvertisements = async () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token;
        setLoading(true);
        try {
            const response = await AdvertisementService.getAdvertisementByCustomer(customerId, token);
            setAdvertisements(response);
            const serviceResponse = await ServicesService.getAllServices(token);
            // Map service IDs to names
            const services = serviceResponse.reduce((map, service) => {
                map[service.id] = service.name;
                return map;
            }, {});
            setServicesMap(services);
        } catch (error) {
            console.error('Failed to fetch advertisements:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (advertisement) => {
        setSelectedAd(advertisement);
        setShowAdvertisementModal(true);
    };

    const handleEditAdvertisementSubmit = async (data) => {
        setFormLoading(true);
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token
        try {
            const response = await AdvertisementService.updateAdvertisement(data.id, data, token);
            alert(response);
        } catch (error) {
            console.error('Error processing AD:', error.message);
        } finally {
            setFormLoading(false);
            setShowAdvertisementModal(false);
            fetchAdvertisements();
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this AD?')) {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const token = storedUser?.token
            try {
                const response = await AdvertisementService.deleteAdvertisement(id, token);
                alert(response);
            } catch (error) {
                console.error('Error processing AD:', error.message);
            } finally {
                setShowAdvertisementModal(false);
                fetchAdvertisements();
            }
        }
    };

    const handleActivate = async (id, active) => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const token = storedUser?.token
        try {
            if (active) {
                if (window.confirm('Are you sure you want to deactivate this AD?')) {
                    const response = await AdvertisementService.deactivateAdvertisement(id, token);
                    alert(response);
                }
            } else {
                if (window.confirm('Are you sure you want to activate this AD?')) {
                    const response = await AdvertisementService.activateAdvertisement(id, token);
                    alert(response);
                }
            }
        } catch (error) {
            console.error(`Error updating advertisement`, error.message);
        } finally{
            fetchAdvertisements();
        }
    };

    const handleChangeImage = (id) => {
        setAdId(id);
        setShowImageUploadModal(true);
    };

    const handleAssignPosition = (advertisement) => {
        setSelectedAd(advertisement);
        setShowPositionModal(true);
    };

    return (
        <>
            <Modal className='modal_style' show={show} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Customer Advertisements</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : advertisements.length === 0 ? (
                        <p>No advertisements found for this customer.</p>  
                    ) : (
                        advertisements.map(advertisement => (
                            <AdvertisementCard
                                key={advertisement.id}
                                advertisement={advertisement}
                                servicesMap={servicesMap}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                                onActivate={handleActivate}
                                onChangeImage={handleChangeImage}
                                assignPosition={handleAssignPosition}
                            />
                        ))
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <AdvertisementModal
                show={showAdvertisementModal}
                handleClose={() => setShowAdvertisementModal(false)}
                handleSubmit={handleEditAdvertisementSubmit}
                advertisement={selectedAd}
                response={formLoading}
            />

            <ImageUploadModal
                show={showImageUploadModal}
                handleClose={() => setShowImageUploadModal(false)}
                advertisementId={adId}
            />

            <PositionModal
                show={showPositionModal}
                handleClose={() => setShowPositionModal(false)}
                ad={selectedAd}
            />
        </>
    );
}

export default ViewAdvertisementsModal;
