import React, { createContext, useState, useEffect } from 'react';
import AuthService from '../services/AuthService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const savedUser  = AuthService.getCurrentUser();
        if (savedUser ) {
            setUser(savedUser );
        }
    }, []);

    const login = async (username, password) => {
        const data = await AuthService.login(username, password);
        setUser(data);
        return data;
    };

    const logout = () => {
        AuthService.logout();
        setUser(null);
    };

    const isLoggedIn = () => {
        return !!localStorage.getItem('user');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, isLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
