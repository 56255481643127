import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl;

const getPlanImage = API_URL+'image/plansimage/';
const getAdsImage = API_URL+'image/adsimage/';

const imageService ={
 getPlanImage,
 getAdsImage

};

export default imageService