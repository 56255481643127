import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/subcategories";

const getAllSubcategories = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/all`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Subcategory');       
    }
};

const getSubcategoryById = async (id, token) => {
    try {        
    const response = await axios.get(`${API_URL}/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
    } catch (error) {
         throw new Error(error.response ? error.response.data : 'Failed to get Subcategory');       
    }
};

const createSubcategory = async (subcategoryDTO, token) => {
    try {
        const response = await axios.post(API_URL, subcategoryDTO, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to create Subcategory');       
    }
};

const updateSubcategory = async (id, subcategoryDTO, token) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, subcategoryDTO, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to update Subcategory');       
    }
};

const deleteSubcategory = async (id, token) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delete Subcategory');       
    }
};

const getSubcategoriesByCategory = async (categoryId, token) => {
    try {
        const response = await axios.get(`${API_URL}/byCategoryAll/${categoryId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Subcategory');       
    }
};

const SubcategoryService = {
    getAllSubcategories,
    getSubcategoryById,
    createSubcategory,
    updateSubcategory,
    deleteSubcategory,
    getSubcategoriesByCategory
};

export default SubcategoryService;
