import React, { useEffect, useState } from 'react';
import { Button, Form, ListGroup, Card } from 'react-bootstrap';
import CustomerService from '../../services/CustomerService';
import customerNoteService from '../../services/CustomerNoteService';
import FollowUpService from '../../services/FollowUpService';

const CustomerStatusUpdate = ({ executiveId, executiveName, customerId, handleFollowUpAdded }) => {
    const [comment, setComment] = useState('');
    const [notes, setNotes] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const [message, setMessage] = useState('');
    const [followUpDate, setFollowUpDate] = useState('');
    const [followUpTime, setFollowUpTime] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        fetchCustomerNote();
    }, [customerId]);

    const fetchCustomerNote = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            if (customerId) {
                const response = await customerNoteService.getNotesByCustomerId(customerId, token);
                setNotes(response);
            } else {
                setNotes([]);
            }
        } catch (error) {
            console.error('Error fetching customer notes:', error.message);
        }
    };

    const handleStatusUpdate = async (newStatus) => {
        if (window.confirm('Are you sure you want to Update this customer?')) {
            try {
                const token = JSON.parse(localStorage.getItem('user')).token;
                if (customerId) {
                    await CustomerService.updateStatus(customerId, newStatus, token);
                    alert('Status updated successfully!');
                    fetchCustomerNote(); // Refresh notes after status update
                } else {
                    alert('No customer is selected');
                }
            } catch (error) {
                console.error('Error updating status:', error.message);
            }
        }
    };

    const handleCommentSubmit = async (e) => {
        setSubmitting(true);
        e.preventDefault();
        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            if (customerId && comment) {
                const noteDTO = {
                    note: comment,
                    customerId: customerId,
                    executiveName: executiveName
                };
                await customerNoteService.createNote(noteDTO, token);
                await CustomerService.updateExecutive(customerId, executiveId, token)
                setNotes([...notes, { ...noteDTO, createdDate: new Date().toISOString() }]); // Add new note with current date
                setComment('');
                alert('Comment added successfully!');
            } else {
                alert('Please provide a comment and select a customer.');
            }
        } catch (error) {
            console.error('Error adding comment:', error.message);
        } finally {
            setSubmitting(false);
        }
    };

    const handleFollowUp = async (e) =>{
        setSubmitting(true)
        e.preventDefault();
        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            if (customerId) {
                // Combine the date and time into a single DateTime string in ISO format
                const followUpDateTime = `${followUpDate}T${followUpTime}`;

                const followUpData = {
                    message,
                    followUpDate: followUpDateTime,
                    customerId,
                    executiveId
                };

                const response = await FollowUpService.createFollowUp(followUpData, token);
                setMessage('');
                setFollowUpDate('');
                setFollowUpTime('');
                //alert('Follow-Up added successfully!');
                handleFollowUpAdded(response);
            } else {
                alert('No customer is selected');
            }
        } catch (error) {
            console.error('Error adding Follow-Up:', error.message);
        } finally{
            setSubmitting(false);
        }
    };

    return (
        <div className='right_content'>
            <h6>Selected customer Id : {customerId}</h6>
            <div className="status-buttons mb-3 justify-content-center">
                <Button className='btn btn-success' onClick={() => handleStatusUpdate('interested')}>Interested</Button>
                <Button className='btn btn-danger' onClick={() => handleStatusUpdate('not_interested')}>Not Interested</Button>
                <Button className='btn btn-primary' onClick={() => handleStatusUpdate('call')}>Call</Button>
                <Button className='btn btn-secondary' onClick={() => handleStatusUpdate('corps')}>Corps</Button>
                <Button className='btn btn-warning' onClick={() => handleStatusUpdate('our_consumers')}>Our Consumers</Button>
                <Button className='btn btn-info' onClick={() => handleStatusUpdate('voice_mail')}>Voice Mail</Button>
            </div>

            <div className='followup_style text-left mb-2'>
            <h4 className='d-flex' onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer'}}>
                <i className="fa-solid fa-plus-square mr-1"></i>
                Add Follow Ups
                <i className={`${isExpanded ? "fas fa-chevron-circle-up" : "fas fa-chevron-circle-down"} ml-auto`}></i>
            </h4>
            {isExpanded && (
            <Form onSubmit={handleFollowUp} className='followup_form row'>
                <Form.Group controlId="message" className='col-md-12'>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter follow-up message"
                        required
                    />
                </Form.Group>

                <Form.Group controlId="followUpDate" className="col-md-6">
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={followUpDate}
                        onChange={(e) => setFollowUpDate(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="followUpTime" className="col-md-6">
                    <Form.Label>Select Time</Form.Label>
                    <Form.Control
                        type="time"
                        value={followUpTime}
                        onChange={(e) => setFollowUpTime(e.target.value)}
                        required
                    />
                </Form.Group>

                <div className="notes_save_btn">
                    <Button className="btn primary mt-2 w-100" type="submit" disabled={submitting}>
                        Save Follow-Up
                    </Button>
                </div>
            </Form>
            )}
            </div>

            <div className='followup_style text-left mb-2'>
            <h4><i className="fa-solid fa-plus-square"></i> Add Comments</h4>
            <Form onSubmit={handleCommentSubmit} className='notes_textarea'>
                <Form.Group controlId="comment">
                    <Form.Control
                        as="textarea"
                        rows={2}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Enter comment or note"
                        required
                    />
                </Form.Group>
                <div className="notes_save_btn">
                    <Button className="btn primary mt-2 w-100" type="submit" disabled={submitting}>
                        Save
                    </Button>
                </div>
            </Form>
            </div>

            <Card className='mt-2 notes_message'>
                <Card.Header className='bg-secondary text-light py-1'>Notes / Comments</Card.Header>
                <Card.Body style={{ maxHeight: '350px', overflowY: 'auto' }}>
                    <ListGroup className='px-2'>
                        {notes.map((note, index) => (
                            <ListGroup.Item key={index} className="row d-flex justify-content-between align-items-start px-0 py-1">
                                <div className="text-left col-md-12 d-flex justify-content-between align-items-center">
                                    <div className='exe_name'><strong>{note.executiveName}</strong></div>
                                    <div className="text-muted text-end notes_date">
                                    {new Date(note.createdDate).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
                                    </div>
                                </div>
                                <div className='col-md-12 text-left'>
                                    <div className='notes_content'>{note.note}</div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
        </div>
    );
};

export default CustomerStatusUpdate;
