import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import logo from '../login-logo.png';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const user = await login(username, password);
            if (user && user.role) {
                const role = user.role.replace(/^\[|\]$/g, '');
                switch (role) {
                    case 'ROLE_ADMIN':
                        navigate('/admin-dashboard');
                        break;
                    case 'ROLE_SALES_MANAGER':
                        navigate('/manager-dashboard');
                        break;
                    case 'ROLE_SALES_EXECUTIVE':
                        navigate('/executive-dashboard');
                        break;
                    case 'ROLE_SEO_EXECUTIVE':
                        navigate('/seo-dashboard');
                        break;
                    default:
                        navigate('/unauthorised');
                        break;
                }
            } else {
                navigate('/unauthorised');
            }
        } catch (error) {
            setErrorMessage(error.message || 'Login failed');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className='login_page'>
            <div className="container">
                <div className='row d-flex justify-content-center align-items-center vh-100'>
                    <div className='col-md-4'>
                        <div className="card login_panel">
                            <div className="card-body">
                                <h2 className="card-title text-center mb-4 login_logo">
                                    <img src={logo} alt={logo} className='img-fluid'/> Sign In Access 
                                </h2>
                                {errorMessage && <div className="alert alert-danger py-2 px-1 text-center rounded-0">{errorMessage}</div>}
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group"> 
                                        <label htmlFor="username" className="h5 form-label"><i className="fa-solid fa-user"></i> Username</label>
                                        <input
                                            type="text"
                                            id="username"
                                            className="form-control"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="password" className="h5 form-label"><i className="fa-solid fa-lock"></i> Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            className="form-control"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-check text-right mb-2 mt-2 show_pass"> 
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showPassword"
                                        checked={showPassword}
                                        onChange={(e) => setShowPassword(e.target.checked)}
                                        />
                                        <label className="form-check-label text-light small" htmlFor="showPassword">
                                        Show Password
                                        </label>
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100 btn-theme" disabled={loading}>
                                        {loading ? (
                                            <span>
                                                <i className="fas fa-spinner fa-spin"></i> Please wait...
                                            </span>
                                        ) : (
                                            <span>
                                                <i className="fas fa-sign-in-alt"></i> Login
                                            </span>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default LoginPage;
