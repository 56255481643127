import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';

const AutocompleteAddress = ({current, onPlaceChanged }) => {
  const [address, setAddress] = useState(current || '');

  // Update local state if `current` prop changes
  useEffect(() => {
    setAddress(current || '');
  }, [current]);
    
  const handlePlaceChanged = (autocomplete) => {    
    const place = autocomplete.getPlace();
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const addressComponents = place.address_components;

      let city = '';
      let state = '';
      let country = '';

      addressComponents.forEach((component) => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
      
      setAddress(place.formatted_address);
      onPlaceChanged && onPlaceChanged({
        formatted_address: place.formatted_address,
        lat,
        lng,
        city,
        state,
        country,
      });
      
    } else {
      console.error('No geometry available for place:', place);
    }
  };

  return (   
      <Autocomplete
        onLoad={(autocomplete) => {
          autocomplete.addListener('place_changed', () => handlePlaceChanged(autocomplete));
        }}
      >
        <input
          type="text"
          placeholder="Enter an address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="form-control"
        />
      </Autocomplete>
  );
};

export default AutocompleteAddress;
