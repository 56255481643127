import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"auth/";
const LOGOUT_URL = `${BaseUrl.baseUrl}logout`;

const login = async (username, password) => {
    try {
        const response = await axios.post(API_URL + "login", { username, password });
        if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to login');       
    }
};

const logout = async () => {
  try {
    await axios.post(LOGOUT_URL, {});
    localStorage.removeItem("user");
    // Optionally, add a redirect or state update after logout
  } catch (error) {
    console.error("Logout failed: ", error);
    // Optionally, handle the error (show a message to the user, etc.)
  }
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    login,
    logout,
    getCurrentUser,
};

export default AuthService;