import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';

const CustomerCsvModal = ({ show, handleClose, handleSubmit, response }) => {
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState('customer');

    useEffect(() => {
        if (!show) {
            // Reset form fields when modal is closed
            setFile(null);
            setFileType('customer');
        }
    }, [show]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({ file, fileType });
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="fileType">
                        <Form.Label>File Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        >
                            <option value="customer">Customer</option>
                            <option value="advertisement">Advertisement</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="file">
                        <Form.Label>CSV File</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            required
                        />
                    </Form.Group>
                    <Row className="justify-content-end modal_footer mt-3">
                    <Button variant="primary modal_btn w-auto" type="submit" disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" /> Uploading...
                            </>
                        ) : (
                            'Upload CSV'
                        )}
                    </Button>
                    </Row> 
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CustomerCsvModal;
