import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';
import SubcategoryService from '../../services/Subcategoryservice';

const ServiceModal = ({ show, handleClose, handleSubmit, service, response }) => {
    const [name, setName] = useState('');
    const [subcategoryId, setSubcategoryId] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (show) {
            const fetchSubcategories = async () => {
                try {
                    const storedUser = JSON.parse(localStorage.getItem('user'));
                    const response = await SubcategoryService.getAllSubcategories(storedUser.token);
                    setSubcategories(response);
                } catch (err) {
                    setError('Failed to fetch subcategories');
                } finally {
                    setLoading(false);
                }
            };
            fetchSubcategories();

            if (service) {
                // If editing, populate form fields with service data
                setName(service.name || '');
                setSubcategoryId(service.subcategoryId || '');
            } else {
                // Reset form fields if adding
                setName('');
                setSubcategoryId('');
            }
        }
    }, [show, service]);

    useEffect(() => {
        if (!show) {
            // Reset form fields when modal is closed
            setName('');
            setSubcategoryId('');
        }
    }, [show]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({ name, subcategoryId, id: service?.id });
    };

    return (
        <Modal className='modal_style' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{service ? 'Edit Service' : 'Add Service'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <p>Loading subcategories...</p>}
                {error && <p className="text-danger">{error}</p>}
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="subcategory">
                        <Form.Label>Subcategory</Form.Label>
                        <Form.Control
                            as="select"
                            value={subcategoryId}
                            onChange={(e) => setSubcategoryId(e.target.value)}
                            required
                        >
                            <option value="">Select subcategory</option>
                            {subcategories.map((subcategory) => (
                                <option key={subcategory.id} value={subcategory.id}>
                                    {subcategory.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2' controlId="name">
                        <Form.Label>Service Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter service name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Row className="justify-content-end modal_footer mt-3">  
                        <Button variant="primary w-auto modal_btn" type="submit" disabled={response}>
                            {response ? (
                                <>
                                    <Spinner animation="border" size="sm" />{' '}
                                    {service ? 'Saving...' : 'Adding...'}
                                </>
                            ) : (
                                service ? 'Save Changes' : 'Add Service'
                            )}
                        </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ServiceModal;
