import React from 'react';
import { Alert, Container, Row, Col, Button  } from 'react-bootstrap';

const CSVResponseSummary = ({ response, onClose  }) => {
    if (!response) return null;

    return (
        <Container className="mt-4">
            <Row>
                <Col>
                    <Alert variant="danger" className="text-left">
                        <h4 className="alert-heading">Bulk Operation Summary</h4>
                        <div className="mb-1">
                            <p className="mb-0">Total Count: {response.addedCount + response.notAddedCount}</p>
                            <p className="mb-0">Added Count: {response.addedCount}</p>
                            <p className="mb-0">Not Added Count: {response.notAddedCount}</p>
                        </div>
                        {response.errorMessage && response.errorMessage.length > 0 && (
                            <div>
                            <hr />
                            <p className="mb-1"><strong>Errors:</strong></p>
                            <ul className="pl-3">
                                {response.errorMessage.map((error, index) => (
                                    <li key={index} className="mb-1">{error}</li>
                                ))}
                            </ul>
                        </div>
                        )}
                        <Button
                            variant="link"
                            className="position-absolute top-0 end-0 m-2"
                            onClick={onClose}
                            aria-label="Close"
                        >
                            <i className="fas fa-times" style={{ fontSize: '20px' }}></i>
                        </Button>
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};

export default CSVResponseSummary;
