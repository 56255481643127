import React, { useState } from 'react';

const CustomerTable = ({ customers, viewAdvertisements, addAdvertisement, editCustomer, deleteCustomer, handleSearch, customerId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);

    const searchOptions = [
        { value: 'companyName', label: 'Company Name' },
        { value: 'primaryPhone', label: 'Primary Phone' },
        { value: 'secondaryPhone', label: 'Secondary Phone' },
        { value: 'address', label: 'Address' },
        { value: 'city', label: 'City' },
        { value: 'state', label: 'State' },
        { value: 'country', label: 'Country' },
        { value: 'executiveName', label: 'Executive Name' },
        { value: 'source', label: 'Source' },
        { value: 'email', label: 'Email' },
        { value: 'website', label: 'Website' },
    ];

    const statusColor = {
        INTERESTED: '#198754',
        NOT_INTERESTED: '#dc3545',
        CORPS: '#6c757d',
        CALL: '#0d6efd',
        OUR_CONSUMERS: '#ffc107',
        VOICE_MAIL: '#0dcaf0',
        // Add other status colors as needed
    };

    // Inline function to get the color based on status
    const getStatusStyle = (status) => ({
        backgroundColor: statusColor[status], // Default to white if status is not found
        color: '#fff', // Ensure text is readable
        fontSize: '12px',
        borderRadius: '3px',
        display: 'block',
        padding: '5px 12px',
    });

    const formatDate = (datetime) => {
        return new Date(datetime).toLocaleDateString();
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };

    const handleSearchClick = () => {
        handleSearch(searchTerm, searchBy)
    };

    const handleRowSelection = (id) => {
        setSelectedRowId(id);
        customerId(id); // Optional callback
    };

    return (
        <section className='dashboard_table_column'>
            <div className="container">
                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end mb-3 px-3 data_search_input">
                        <div className="input-group" style={{ maxWidth: '500px' }}>
                            <select
                                className="form-select"
                                value={searchBy}
                                onChange={handleSearchByChange}
                                style={{ maxWidth: '180px' }}
                            >
                                <option value="" >Search By</option>
                                {searchOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Search Term"
                                value={searchTerm}
                                onChange={handleInputChange}
                                disabled={!searchBy}
                            />
                            <button
                                className="btn btn-warning"
                                onClick={handleSearchClick}
                                disabled={!searchBy}
                            >
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </div>
                    </div>

                    <div className='col-md-12 px-3'>
                        <div className='table_data_manage table-responsive'>
                            <table className="compact-table table-bordered table-hover">
                                <thead className='table-light'>
                                    <tr>
                                        <th>Id</th>
                                        <th>Actions</th>
                                        <th>Excecutive</th>
                                        <th>Status</th>
                                        <th>Company</th>
                                        <th>Pr_Phone</th>
                                        <th>Sc_Phone</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Country</th>
                                        <th>Source</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customers.length > 0 ? (
                                        customers.map(customer => (
                                            <tr key={customer.id} className={selectedRowId === customer.id ? 'selected' : ''}
                                                onClick={() => handleRowSelection(customer.id)}
                                            >
                                                <td>{customer.id}</td>
                                                <td className='action_btn'>
                                                    <button className="btn p-0" onClick={() => editCustomer(customer)}>
                                                        <i className="fa-solid fa-pen-to-square"></i></button>
                                                    <button className="btn p-0" onClick={() => deleteCustomer(customer.id)}>
                                                        <i className="fa-regular fa-trash-can"></i></button>
                                                    <button className="btn p-0" onClick={() => viewAdvertisements(customer.id)}>
                                                        <i className="fa-solid fa-eye"></i></button>
                                                    <button className="btn p-0" onClick={() => addAdvertisement(customer)}>
                                                        <i className="fa-solid fa-plus"></i></button>
                                                </td>
                                                <td>{customer.executiveName}</td>
                                                <td><span style={getStatusStyle(customer.status)}>{customer.status}</span></td>
                                                <td>{customer.companyName}</td>
                                                <td>{customer.primaryPhone}</td>
                                                <td>{customer.secondaryPhone}</td>
                                                <td>{customer.city}</td>
                                                <td>{customer.state}</td>
                                                <td>{customer.country}</td>
                                                <td>{customer.source}</td>
                                                <td>{formatDate(customer.createdDate)}</td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td colSpan="12" className="text-center">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>


        </section>
    );
};

export default CustomerTable;
