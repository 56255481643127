import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import imageService from '../../services/ImageServie';

function AdvertisementCard({ advertisement, servicesMap, onEdit, onDelete, onActivate, onChangeImage, assignPosition }) {
   const services = (advertisement.serviceIds || []).map(id => servicesMap[id] || '');

   return (
        <Card className="mb-3">
            <Row className='mx-0'>
                {advertisement.imageName && (
                    <Col md={4} className='px-0'>
                        <Card.Img className='rounded' variant="top"
                            src={`${imageService.getAdsImage}${advertisement.imageName || 'placeholder-image.jpg'}`}
                            alt={advertisement.companyName}
                        />
                    </Col>
                )}
                <Col md={advertisement.imageName ? 8 : 12}>
                    <Card.Body className='p-0 py-2 customer_adv_content'>
                        <Card.Title>{advertisement.companyName}</Card.Title>
                        <Row>
                            <Col md={12}>
                                <div className='customer_adv_Info'>
                                    <p><strong>Address:</strong> {advertisement.address} {advertisement.city}, {advertisement.state}</p>
                                    <p><strong>Ph: </strong>{advertisement.phone}  |  <strong>Work Type: </strong>{advertisement.shopType} </p>
                                    <p><strong>Work Hrs:</strong> {advertisement.workingHours}</p> 
                                    <p><strong>Website:</strong> <a href={advertisement.website}>{advertisement.website}</a> | <strong>Email:</strong> {advertisement.email}</p>
                                </div>

                                <div className="customer_adv_descr">
                                    <p className='mb-1 mt-1'><strong>Description:</strong> {advertisement.description}</p>
                                    <p className='mb-1 mt-1'><strong>Highlight:</strong> {advertisement.highlight}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="customer_adv_service">
                                    <p className='mb-1'><strong>Services:</strong> {services.join(' | ')}</p> 
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-1 status-buttons mx-0">
                            <Col md={12} className='customer_adv_btn px-0'>
                                <Button variant="primary" onClick={() => onEdit(advertisement)}>Edit</Button>{' '}
                                <Button variant="danger" onClick={() => onDelete(advertisement.id)}>Delete</Button>{' '}
                                <Button variant={advertisement.active ? 'warning' : 'success'} onClick={() => onActivate(advertisement.id, advertisement.active)}>
                                    {advertisement.active ? 'Deactivate' : 'Activate'}
                                </Button>{' '}
                                <Button variant="secondary" onClick={() => onChangeImage(advertisement.id)}>Change Image</Button>{' '}
                                <Button variant="success" onClick={() => assignPosition(advertisement)}>Assign Position</Button> 
                            </Col>
                        </Row>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
}

export default AdvertisementCard;
