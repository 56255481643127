import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
    // If there is only one page or less, do not render pagination
    if (totalPages <= 1) {
        return null;
    }

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        // Adjust for zero-based index
        const startPage = Math.max(0, currentPage - 2);
        const endPage = Math.min(totalPages - 1, startPage + maxPagesToShow - 1);

        if (startPage > 0) {
            pageNumbers.push(0); // Show the first page (zero-based index)
            if (startPage > 1) {
                pageNumbers.push('...');
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        if (endPage < totalPages - 1) {
            if (endPage < totalPages - 2) {
                pageNumbers.push('...');
            }
            pageNumbers.push(totalPages - 1); // Show the last page (zero-based index)
        }

        return pageNumbers;
    };

    const pageNumbers = getPageNumbers();

    return (
        <Pagination className='mt-3 mb-0 d-flex justify-content-end px-3'> 
            <Pagination.Prev
                onClick={() => onPageChange(Math.max(0, currentPage - 1))}
                disabled={currentPage === 0}
            />
            {pageNumbers.map((number, index) =>
                number === '...' ? (
                    <Pagination.Ellipsis key={index} />
                ) : (
                    <Pagination.Item
                        key={index}
                        active={number === currentPage}
                        onClick={() => onPageChange(number)}
                    >
                        {number + 1} {/* Adjust for human-readable page numbers */}
                    </Pagination.Item>
                )
            )}
            <Pagination.Next
                onClick={() => onPageChange(Math.min(totalPages - 1, currentPage + 1))}
                disabled={currentPage === totalPages - 1}
            />
        </Pagination>
    );
};

export default PaginationComponent;
