import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/services";

const getAllServices = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/all`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to get Services');       
    }
};

const getAllServicesPages = async (page, size, token) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                page: page,
                size: size,
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Services');       
    }
};

const getServiceById = async (id, token) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Service');       
    }
};

const createService = async (serviceDTO, token) => {
    try {
        const response = await axios.post(API_URL, serviceDTO, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to create Service');       
    }
};

const updateService = async (id, serviceDTO, token) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, serviceDTO, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to update Service');       
    }
};

const deleteService = async (id, token) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delet Service');       
    }
};

const getServicesBySubcategory = async (subcategoryId, token) => {
    try {
        const response = await axios.get(`${API_URL}/bySubcategoryAll/${subcategoryId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Services');       
    }
};

const getServicesBySubcategoryPages = async (page, size, subcategoryId, token) => {
    try {
        const response = await axios.get(`${API_URL}/bySubcategory/${subcategoryId}`, {
            params: {
                page: page,
                size: size,
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Services');       
    }
};

const ServicesService = {
    getAllServices,
    getAllServicesPages,
    getServiceById,
    createService,
    updateService,
    deleteService,
    getServicesBySubcategory,
    getServicesBySubcategoryPages
};

export default ServicesService;
