import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/crm-users";

const getCRMUsers = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/all`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get CRM User');       
    }
};

const getLogInUser = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/logInUser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get CRM User');       
    }
};

const getCRMUsersPages = async (page, size, token) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                page: page,
                size: size,
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get CRM User');       
    }
};

const getCRMUserById = async (id, token) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get CRM User');       
    }
};

const getCRMUserByRole = async (role, token) => {
    try {
        const response = await axios.get(`${API_URL}/role/${role}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get CRM User');       
    }
};

const createCRMUser = async (userDetails, token) => {
    try {
        const response = await axios.post(API_URL, userDetails, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to create CRM User');
    }
};

const updateCRMUser = async (id, userDetails, token) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, userDetails, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;       
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to update CRM User');
    }
};

const deleteCRMUser = async (id, token) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delete CRM User');
    }
};

const updatePassword = async (id, passwordDetails, token) => {
    try {
        const response = await axios.patch(`${API_URL}/${id}/password`, passwordDetails, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to update password CRM User');
    }
};

const addCities = async (data, token) => {
    try {
        const response = await axios.post(`${API_URL}/add-cities`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to add cities in CRM User');
    }
};

const removeCities = async (data, token) => {
    try {
        const response = await axios.post(`${API_URL}/remove-cities`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to remove cities in CRM User');
    }
};

const CRMUserService = {
    getCRMUsers,
    getCRMUsersPages,
    getCRMUserById,
    getLogInUser,
    getCRMUserByRole,    
    createCRMUser,
    updateCRMUser,
    deleteCRMUser,
    updatePassword,
    addCities,
    removeCities
};

export default CRMUserService;