import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, Spinner, Col } from 'react-bootstrap';
import BlogService from '../../services/BlogService';

const BlogModal = ({ show, handleClose, handleSubmit, blog }) => {
    const [title, setTitle] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [keywords, setKeywords] = useState('');
    const [content, setContent] = useState('');
    const [featuredImage, setFeaturedImage] = useState(null);
    const [listingImage, setListingImage] = useState(null);

    const [preview, setPreview] = useState(false); // Toggle preview mode

    const [submitting, setSubmitting] = useState(false); // Toggle preview mode

    // Image preview URLs
    const [featuredImagePreview, setFeaturedImagePreview] = useState(null);
    const [listingImagePreview, setListingImagePreview] = useState(null);

    // Populate the form when the modal is shown (for editing)
    useEffect(() => {
        if (show) {
            if (blog) {
                setTitle(blog.title || '');
                setMetaTitle(blog.metaTitle || '');
                setMetaDescription(blog.metaDescription || '');
                setKeywords(blog.keywords?.join(', ') || ''); // Keywords stored as a comma-separated string
                setContent(blog.content || '');
                setFeaturedImage(null); // Reset file input for image
                setListingImage(null);  // Reset file input for image
                setFeaturedImagePreview(null);
                setListingImagePreview(null);
            } else {
                // Reset form fields for new blog
                setTitle('');
                setMetaTitle('');
                setMetaDescription('');
                setKeywords('');
                setContent('');
                setFeaturedImage(null);
                setListingImage(null);
                setFeaturedImagePreview(null);
                setListingImagePreview(null);
            }
        }
    }, [show, blog]);

        // Handle image preview for featured and listing images
        useEffect(() => {
            if (featuredImage) {
                setFeaturedImagePreview(URL.createObjectURL(featuredImage));
            }
            if (listingImage) {
                setListingImagePreview(URL.createObjectURL(listingImage));
            }
        }, [featuredImage, listingImage]);

    const onSubmit = (e) => {
        e.preventDefault();
            // Create the BlogDTO object
        const blogDTO = {
            title,
            metaTitle,
            metaDescription,
            keywords: keywords.split(',').map(keyword => keyword.trim()), // Convert comma-separated string to array
            content
        };

        // Prepare data for submission
        const formData = new FormData();

         // Append blog data as a JSON blob
        formData.append('blog', new Blob([JSON.stringify(blogDTO)], { type: 'application/json' }));

        if (featuredImage) {
            formData.append('featuredImage', featuredImage);
        }
        if (listingImage) {
            formData.append('listingImage', listingImage);
        }

        handleBlogSubmit(formData, blog?.id); // Pass formData and blog ID (if editing)

    };

    const handleBlogSubmit = async (formData, blogId) => {
        try {
            setSubmitting(true);
            const token = JSON.parse(localStorage.getItem('user')).token;
            if (blogId) {              
                const response = await BlogService.updateBlog(blogId, formData, token);
                alert(response);                
                handleClose();
                handleSubmit(true);
            } else {              
                const response = await BlogService.createBlog(formData, token);
                alert(response);
                handleClose();
                handleSubmit(true);
            }
        } catch (error) {
            console.error("Error submitting blog:", error);
            alert(error.message)
        } finally{
            setSubmitting(false);
        }
    };

    const handleCloseModal = () => {
        // Reset form fields when closing the modal
        setTitle('');
        setMetaTitle('');
        setMetaDescription('');
        setKeywords('');
        setContent('');
        setFeaturedImage(null);
        setListingImage(null);
        handleClose();
        setPreview(false)
    };

    // Toggle preview mode
    const handlePreview = () => {
        setPreview(!preview);
    };    

    // Utility function to truncate long filenames
    const truncateFileName = (fileName, maxLength = 20) => {
        if (fileName.length > maxLength) {
            const start = fileName.slice(0, Math.floor(maxLength / 2));
            const end = fileName.slice(-Math.floor(maxLength / 2));
            return `${start}...${end}`;
        }
        return fileName;
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleCloseModal} size="xl" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{blog ? 'Edit Blog' : 'Add Blog'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {preview ? (
                    // Blog Preview Section
                    <div className="blog-preview">
                        <p className='mb-1'><strong>Title :</strong> {title}</p>
                        <p className='mb-1'><strong>Meta_Title:</strong> {metaTitle}</p>
                        <p className='mb-1'><strong>Meta_Desc:</strong> {metaDescription}</p>
                        <p className='mb-1'><strong>Keywords:</strong> {keywords}</p> 
                        <hr></hr>
                        <h1>{title}</h1>
                        {featuredImagePreview && <img src={featuredImagePreview} alt="Featured" style={{ maxWidth: '100%' }} />}
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        {listingImagePreview && <img src={listingImagePreview} alt="Listing" style={{ maxWidth: '100%', marginTop: '20px' }} />}
                    
                        <Row className="justify-content-end modal_footer mt-3">
                        <Button variant="secondary modal_btn w-auto" onClick={handlePreview} className="mr-2">
                            {preview ? 'Back to Form' : 'Preview'}
                        </Button>
                        </Row>
                    </div>
                ) : (
                <Form onSubmit={onSubmit}>
                <Row>
                    <Col md={4}>
                    <Form.Group controlId="title">
                        <Form.Label>Blog Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter blog title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Form.Group>
                    </Col>
                    <Col md={4}>
                    <Form.Group controlId="metaTitle">
                        <Form.Label>Meta Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter meta title for SEO"
                            value={metaTitle}
                            onChange={(e) => setMetaTitle(e.target.value)}
                        />
                    </Form.Group>
                    </Col>
                    <Col md={4}>
                    <Form.Group controlId="metaDescription">
                        <Form.Label>Meta Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter meta description for SEO"
                            value={metaDescription}
                            onChange={(e) => setMetaDescription(e.target.value)}
                        />
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                    <Form.Group controlId="keywords">
                        <Form.Label>Keywords</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter keywords (comma separated)"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                        />
                    </Form.Group>                    
                    </Col>
                    <Col md={4}>
                    <Form.Group controlId="featuredImage">
                        <Form.Label>Featured Image</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setFeaturedImage(e.target.files[0])}
                        />
                        {featuredImage && <Form.Text className="text-muted">{truncateFileName(featuredImage.name, 30)}</Form.Text>}
                    </Form.Group>
                    </Col>
                    <Col md={4}>
                    <Form.Group controlId="listingImage">
                        <Form.Label>Listing Image</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setListingImage(e.target.files[0])}
                        />
                         {listingImage && <Form.Text className="text-muted">{truncateFileName(listingImage.name, 30)}</Form.Text>}
                    </Form.Group>
                    </Col>
                </Row>
                    <Form.Group controlId="content">
                        <Form.Label>Content</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={6}
                            placeholder="Enter blog content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            style={{ minHeight: '300px', overflowY: 'scroll' }}  // Ensure it can scroll
                        />
                    </Form.Group>
                    <Row className="justify-content-end modal_footer mt-3">
                        <Button variant="secondary modal_btn w-auto" onClick={handlePreview} className="mr-2">
                            {preview ? 'Back to Form' : 'Preview'}
                        </Button>
                        <Button variant="primary modal_btn w-auto" type="submit" disabled={submitting}>
                            {submitting ? (
                                <>
                                    <Spinner animation="border" size="sm" />{' '}
                                    {blog ? 'Saving...' : 'Adding...'}
                                </>
                            ) : (
                                blog ? 'Save Changes' : 'Add Blog'
                            )}
                        </Button>
                    </Row>
                </Form>
            )}
            </Modal.Body>
        </Modal>
    );
};

export default BlogModal;
