import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';

const CategoryModal = ({ show, handleClose, handleSubmit, category, response }) => {
    const [name, setName] = useState('');

    useEffect(() => {
        if (show) {
            if (category) {
                // If editing, set form fields with category data
                setName(category.name || '');
            } else {
                // Reset form fields if adding
                setName('');
            }
        }
    }, [show, category]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({ 
            name,
            id: category?.id // Include ID if editing
        });
    };

    const handleCloseModal = () => {
        // Reset form fields when closing the modal
        setName('');
        handleClose();
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{category ? 'Edit Category' : 'Add Category'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="justify-content-end modal_footer mt-3"> 
                    <Button variant="primary modal_btn w-auto" type="submit" disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" />{' '}
                                {category ? 'Saving...' : 'Adding...'}
                            </>
                        ) : (
                            category ? 'Save Changes' : 'Add Category'
                        )}
                    </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CategoryModal;
