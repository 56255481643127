import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/advertisements";

const createAdvertisement = async (advertisement, token) => {
    try {
        const response = await axios.post(`${API_URL}/create`, advertisement, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to create AD');       
    }
};

const updateAdvertisement = async (id, advertisement, token) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, advertisement, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to Update AD');       
    }
};

const deleteAdvertisement = async (id, token) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delete AD');       
    }
};

const getAdvertisementByCustomer = async (customerId, token) => {
    try {
        const response = await axios.get(`${API_URL}/customer/${customerId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get AD');       
    }
};

const getAdvertisements = async (page, size, token) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                page,
                size,
              },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get AD');       
    }
};

const activateAdvertisement = async (Id, token) => {
    try {
        const response = await axios.put(`${API_URL}/${Id}/activate`, {} , {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to activate AD');       
    }
};

const deactivateAdvertisement = async (Id, token) => {
    try {
        const response = await axios.put(`${API_URL}/${Id}/deactivate`, {} , {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to deactivate AD');       
    }
};

const bulkCreateAdvertisements = async (file, token) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${API_URL}/bulk-create`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to Add CSV');       
    }
};

const uploadImage = async (id, image, token) => {
    const formData = new FormData();
    formData.append('image', image);

    try {
        const response = await axios.put(`${API_URL}/${id}/update-image`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to update image');
    }
};

const getOccupiedPositions = async (subcategoryIds, city, state) => {
    try {
        const response = await axios.get(`${API_URL}/get-positions`, {
            params: {
                subcategoryIds: subcategoryIds.join(','),  // Join the array into a comma-separated string
                city: city,
                state: state
            }});
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch occupied positions');
    }
};

const assignPosition = async (adId, position, token) => {
    try {
        const response = await axios.put(`${API_URL}/assign-position`, { }, {
            params: {
                advertisementId: adId,
                position: position
            },
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to Assign Position');
    }
};

// Activate advertisements by city, state, and country
const activateAdvertisementByCity = async (city, state, country, token) => {
    try {
        const response = await axios.put(`${API_URL}/activate-by-city`, null, {
            params: {
                city: city,
                state: state,
                country: country
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to activate AD by location');
    }
};

// Deactivate advertisements by city, state, and country
const deactivateAdvertisementByCity = async (city, state, country, token) => {
    try {
        const response = await axios.put(`${API_URL}/deactivate-by-city`, null, {
            params: {
                city: city,
                state: state,
                country: country
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to deactivate AD by location');
    }
};

const AdvertisementService = {
    createAdvertisement,
    updateAdvertisement,
    deleteAdvertisement,
    getAdvertisementByCustomer,
    getAdvertisements,
    activateAdvertisement,
    deactivateAdvertisement,
    bulkCreateAdvertisements,
    uploadImage,
    getOccupiedPositions,
    assignPosition,
    activateAdvertisementByCity,
    deactivateAdvertisementByCity
};

export default AdvertisementService;