import axios from "axios";
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl;

const downloadSitemapXml  = async (token) => {
    try {
        const response = await axios.get(`${API_URL}api/sitemap`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob', // Important for downloading file
        });

        // Create a link element to download the file
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sitemap.xml'); // Download as 'sitemap.xml'
        document.body.appendChild(link);
        link.click();
        link.remove(); // Clean up

        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Sitemap XML');
    }
};

const SitemapService ={
    downloadSitemapXml 
};

export default SitemapService