import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/upload-banner";

const uploadBanner = async (image, token) => {
    try {
        const formData = new FormData();
        formData.append('image', image);

        const response = await axios.post(API_URL, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to upload banner');
    }
};


const BannerUploadService = {
    uploadBanner
};

export default BannerUploadService;