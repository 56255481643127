import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, Card, Spinner } from 'react-bootstrap';
import PlanService from '../../services/PlanService';
import CategoryService from '../../services/CategoryService';
import ServicesService from '../../services/ServiceService';
import ServicesDropdown from '../Common/ServicesDropdown';
import SubcategoryService from '../../services/Subcategoryservice';
import SubcategoryCheckboxList from '../Common/SubcategoryCheckboxList';
import AutocompleteAddress from '../GoogleAddressApi/AutocompleteAddress';
import imageService from '../../services/ImageServie';

const AdvertisementModal = ({ show, handleClose, handleSubmit, advertisement, customer, response }) => {
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [shopType, setShopType] = useState('');
    const [workingHours, setWorkingHours] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [highlight, setHighlight] = useState('');
    const [plans, setPlans] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (show) {
            fetchData();
            if (advertisement) {
                setCompanyName(advertisement.companyName || '');
                setPhone(advertisement.phone || '');
                setAddress(advertisement.address || '');
                setCity(advertisement.city || '');
                setState(advertisement.state || '');
                setCountry(advertisement.country || '');
                setLat(advertisement.lat || '');
                setLng(advertisement.lng || '');
                setShopType(advertisement.shopType || '');
                setWorkingHours(advertisement.workingHours || '');
                setWebsite(advertisement.website || '');
                setEmail(advertisement.email || '');
                setDescription(advertisement.description || '');
                setHighlight(advertisement.highlight|| '');
                setSelectedPlan(advertisement.planId || '');
                setSelectedCategory(advertisement.categoryId || '');
                setSelectedSubcategories(advertisement.subcategoryIds.map(String) || []);
                setSelectedServices(advertisement.serviceIds.map(String) || []);
            } else {
                resetForm();
                if (customer) {
                    setCompanyName(customer.companyName || '');
                    setPhone(customer.primaryPhone || '');
                    setAddress(customer.address || '');
                    setCity(customer.city || '');
                    setState(customer.state || '');
                    setCountry(customer.country || '');
                    setWebsite(customer.website || '');
                    setEmail(customer.email || '');
                }
            }
        }
    }, [show, advertisement, customer]);

    useEffect(() => {
        if (selectedCategory) {
            (async () => {
                try {
                    const token = JSON.parse(localStorage.getItem('user')).token;
                    const subcategoriesResponse = await SubcategoryService.getSubcategoriesByCategory(selectedCategory, token);
                    setSubcategories(subcategoriesResponse);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            })();
        } else {
            setSubcategories([]);
        }
    }, [selectedCategory]);

    const fetchData = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('user')).token;
            const [plansResponse, categoriesResponse, servicesResponse] = await Promise.all([
                PlanService.getAllPlans(token),
                CategoryService.getAllCategories(token),
                ServicesService.getAllServices(token)
            ]);

            setPlans(plansResponse);
            setCategories(categoriesResponse);
            setServices(servicesResponse);

            if (selectedCategory) {
                const subcategoriesResponse = await SubcategoryService.getSubcategoriesByCategory(selectedCategory, token);
                setSubcategories(subcategoriesResponse);
            } else {
                setSubcategories([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const resetForm = () => {
        setCompanyName('');
        setPhone('');
        setAddress('');
        setCity('');
        setState('');
        setCountry('');
        setLat('');
        setLng('');
        setShopType('');
        setWorkingHours('');
        setWebsite('');
        setEmail('');
        setDescription('');
        setHighlight('');
        setSelectedPlan('');
        setSelectedCategory('');
        setSelectedSubcategories([]);
        setSelectedServices([]);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const advertisementData = {
            companyName,
            phone,
            address,
            city,
            state,
            country,
            lat,
            lng,
            shopType,
            workingHours,
            website,
            email,
            description,
            highlight,
            planId: selectedPlan,
            categoryId: selectedCategory,
            subcategoryIds: selectedSubcategories,
            serviceIds: selectedServices,
            customerId: customer?.id,
            id: advertisement?.id // Include ID if editing
        };
        handleSubmit(advertisementData);
        resetForm();
    };

    const handlePreview = () => {
        setShowPreview(true);
    };

    const handlePlaceChanged = (place) => {
        setAddress(place.formatted_address);
        setCity(place.city);
        setState(place.state);
        setCountry(place.country);
        setLat(place.lat);
        setLng(place.lng);
    };

    return (
        <>
            <Modal className='modal_style' show={show} onHide={() => { resetForm(); handleClose(); }} size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{advertisement ? 'Edit AD' : 'New AD'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="advertisementForm" onSubmit={onSubmit}>
                        <Row className="mb-1">

                            <Col md={8}>
                                <Row className="mb-1">
                                    <Col md={4}>
                                        <Form.Group controlId="plan">
                                            <Form.Label>Plan</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={selectedPlan}
                                                onChange={(e) => setSelectedPlan(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Plan</option>
                                                {plans.map(plan => (
                                                    <option key={plan.id} value={plan.id}>{plan.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="companyName">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter company name"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter phone number"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group controlId="address">
                                            <Form.Label>Address</Form.Label>
                                            <div className="autocomplete-dropdown">
                                                <AutocompleteAddress current={address} onPlaceChanged={handlePlaceChanged} />
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group controlId="city">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter city"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="state">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter state"
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group controlId="country">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter country"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="website">
                                            <Form.Label>Website</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter website"
                                                value={website}
                                                onChange={(e) => setWebsite(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {selectedPlan === '3' && (
                                    <Col md={12}>
                                        <Form.Group controlId="highlight">
                                            <Form.Label>Highlight</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Special offer, deals and highlights"
                                                value={highlight}
                                                onChange={(e) => setHighlight(e.target.value)}
                                            />                                            
                                        </Form.Group>
                                    </Col>
                                    )}

                                    <Col md={6}>
                                        <Form.Group controlId="shopType">
                                            <Form.Label>Shop Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={shopType}
                                                onChange={(e) => setShopType(e.target.value)}
                                            >
                                                <option value="">Select shop type</option>
                                                <option value="Shop">Shop</option>
                                                <option value="Mobile">Mobile</option>
                                                <option value="Shop/Mobile">Shop/Mobile</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group controlId="workingHours">
                                            <Form.Label>Working Hours</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={workingHours}
                                                onChange={(e) => setWorkingHours(e.target.value)}
                                            >
                                                <option value="">Select Working Hours</option>
                                                <option value="24 Hours Open">24 Hours Open</option>
                                                <option value="12 AM - 8 AM">12 AM - 8 AM (Night Shift)</option>
                                                <option value="8 AM - 4 PM">8 AM - 4 PM (Morning Shift)</option>
                                                <option value="4 PM - 12 AM">4 PM - 12 AM (Evening Shift)</option>
                                                <option value="6 AM - 2 PM">6 AM - 2 PM (Early Morning)</option>
                                                <option value="2 PM - 10 PM">2 PM - 10 PM (Afternoon to Evening)</option>
                                                <option value="10 PM - 6 AM">10 PM - 6 AM (Overnight Shift)</option>
                                                <option value="9 AM - 5 PM">9 AM - 5 PM (Standard Day)</option>
                                                <option value="10 AM - 6 PM">10 AM - 6 PM</option>
                                                <option value="11 AM - 7 PM">11 AM - 7 PM</option>
                                                <option value="12 PM - 8 PM">12 PM - 8 PM</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group className='mb-3' controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Col>

                            <Col md={4}>
                                <Row className="mb-1">
                                    <Col md={12}>
                                        <Form.Group controlId="category">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Control
                                                required
                                                as="select"
                                                value={selectedCategory}
                                                onChange={async (e) => {
                                                    const selectedValue = e.target.value;
                                                    setSelectedCategory(selectedValue);

                                                    if (selectedValue) {
                                                        try {
                                                            const subcategoriesResponse = await SubcategoryService.getSubcategoriesByCategory(
                                                                selectedValue,
                                                                JSON.parse(localStorage.getItem('user')).token
                                                            );
                                                            setSubcategories(subcategoriesResponse);
                                                        } catch (error) {
                                                            console.error('Error fetching subcategories:', error);
                                                        }
                                                    } else {
                                                        setSubcategories([]);
                                                    }
                                                }}
                                            >
                                                <option value="">Select Category</option>
                                                {categories.map((category) => (
                                                    <option key={category.id} value={category.id}>
                                                        {category.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <SubcategoryCheckboxList
                                            subcategories={subcategories}
                                            selectedSubcategories={selectedSubcategories}
                                            setSelectedSubcategories={setSelectedSubcategories}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group controlId="services">
                                            <Form.Label>Services</Form.Label>
                                            <ServicesDropdown
                                                services={services}
                                                selectedServices={selectedServices}
                                                setSelectedServices={setSelectedServices}
                                            />
                                        </Form.Group>
                                    </Col>


                                </Row>
                            </Col>
                        </Row>




                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        resetForm();
                        handleClose();
                    }}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={handlePreview}> 
                        Preview
                    </Button>
                    <Button variant="primary modal_btn" onClick={onSubmit} disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" /> {advertisement ? 'Updating...' : 'Creating...'}
                            </>
                        ) : (
                            advertisement ? 'Update Advertisement' : 'Create Advertisement'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* this modal is use for preview the AD  */}
            <Modal className='modal_style' show={showPreview} onHide={() => setShowPreview(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Advertisement Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className='mx-0'>
                            {plans.find(plan => plan.id == selectedPlan)?.imageName && (
                                <Col md={4} className='px-0'>
                                    <Card.Img className='rounded' variant="top"
                                        src={`${imageService.getPlanImage}${plans.find(plan => plan.id == selectedPlan)?.imageName || 'placeholder-image-url.jpg'}`}
                                    />
                                </Col>
                            )}
                            <Col className='px-0' md={plans.find(plan => plan.id == selectedPlan)?.imageName ? 8 : 12}>
                                <Card.Body className='customer_adv_content py-2 preview_adv_content'> 
                                    <Card.Title>{companyName}</Card.Title>
                                    <Row>
                                        <Col md={12}>
                                            <div className='customer_adv_Info'>
                                                <p><strong>Address:</strong> {address} {city}, {state}</p>
                                                <p><strong>Ph: </strong>{phone}  |  <strong>Work Type: </strong>{shopType} </p>
                                                <p><strong>Work Hrs:</strong> {workingHours}</p>
                                                <p><strong>Website:</strong> <a href={website}>{website}</a> | <strong>Email:</strong> {email}</p>
                                                <p><strong>Highlight:</strong> {highlight}</p>
                                            </div>

                                            <div className="customer_adv_descr">
                                                <p className='mb-1 mt-1'><strong>Description:</strong> {description}</p>
                                            </div>

                                        </Col>

                                        <Col md={12}>
                                            <div className="customer_adv_service">
                                                <p className='mb-1'><strong>Services:</strong> {services.filter(service => selectedServices.includes(service.id.toString())).map(service => service.name).join(' | ') || " "}</p>
                                            </div>
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
                <Modal.Footer className='mt-3'>
                    <Button variant="secondary" onClick={() => setShowPreview(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default AdvertisementModal;
