import React, { useState } from 'react';

const StatusButtons = ({ setShowCustomers }) => {
  const [activeButton, setActiveButton] = useState('all');

  const handleButtonClick = (status) => {
    setActiveButton(status);
    setShowCustomers(status);
  };

  return (
    <div className="status-buttons mb-3 table_btn-filter">
      <button
        type="button"
        className={`btn btn-outline-custom ${activeButton === 'all' ? 'active' : ''}`}
        onClick={() => handleButtonClick('all')}
      >
        All
      </button>
      <button
        type="button"
        className={`btn btn-outline-success ${activeButton === 'interested' ? 'active' : ''}`}
        onClick={() => handleButtonClick('interested')}
      >
        Interested
      </button>
      <button
        type="button"
        className={`btn btn-outline-danger ${activeButton === 'not_interested' ? 'active' : ''}`}
        onClick={() => handleButtonClick('not_interested')}
      >
        Not Interested
      </button>
      <button
        type="button"
        className={`btn btn-outline-primary ${activeButton === 'call' ? 'active' : ''}`}
        onClick={() => handleButtonClick('call')}
      >
        Call
      </button>
      <button
        type="button"
        className={`btn btn-outline-secondary ${activeButton === 'corps' ? 'active' : ''}`}
        onClick={() => handleButtonClick('corps')}
      >
        Corps
      </button>
      <button
        type="button"
        className={`btn btn-outline-warning ${activeButton === 'our_consumers' ? 'active' : ''}`}
        onClick={() => handleButtonClick('our_consumers')}
      >
        Our Consumers
      </button>
      <button
        type="button"
        className={`btn btn-outline-info ${activeButton === 'voice_mail' ? 'active' : ''}`}
        onClick={() => handleButtonClick('voice_mail')}
      >
        Voice Mail
      </button>
      <button
        type="button"
        className={`btn btn-outline-success ${activeButton === 'verified' ? 'active' : ''}`}
        onClick={() => handleButtonClick('verified')}
      >
        Verified
      </button>
      <button
        type="button"
        className={`btn btn-outline-danger ${activeButton === 'non_verified' ? 'active' : ''}`}
        onClick={() => handleButtonClick('non_verified')}
      >
        Non Verified
      </button>
    </div>
  );
};

export default StatusButtons;
