import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Spinner, Form } from "react-bootstrap";
import FollowUpService from "../../services/FollowUpService";

const ViewFollowUps = ({ executiveId, show, onClose, handleFollowUpUpdate }) => {
    const [followUps, setFollowUps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadFollowUp, setLoadFollowUp] = useState(true);
    const [followUpDate, setFollowUpDate] = useState('');

    const handleClose=()=>{
        setFollowUps([]);
        setFollowUpDate('');
        onClose();
    };
    useEffect(() => {
        const fetchFollowUps = async () => {
            setLoadFollowUp(true);
            try {
                const token = JSON.parse(localStorage.getItem('user')).token;
                if (executiveId) {
                    const data = await FollowUpService.getFollowUp(executiveId, null ,token);
                    setFollowUps(data);
                }
            } catch (error) {
                console.error('Failed to fetch Followups:', error);
            } finally {
                setLoadFollowUp(false);
            }
        }
        if (show) {
            fetchFollowUps();
        }
    }, [executiveId, show]);

    const handleComplete = async (followUpId) => {
        try {
            setLoading(true);
            const token = JSON.parse(localStorage.getItem('user')).token;
            const response = await FollowUpService.followUpComplete(followUpId, token);
            alert(response);
            // Update the state by marking the follow-up as completed
            setFollowUps(prevFollowUps =>
                prevFollowUps.map(followUp =>
                    followUp.id === followUpId ? { ...followUp, completed: true } : followUp
                )
            );
            handleFollowUpUpdate(response);
        } catch (error) {
            console.error('Failed to fetch Followups:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (followUpId) => {
        try {
            setLoading(true);
            const token = JSON.parse(localStorage.getItem('user')).token;
            const response = await FollowUpService.followUpDelete(followUpId, token);
            alert(response);
            // Update the state by removing the deleted follow-up
            setFollowUps(prevFollowUps =>
                prevFollowUps.filter(followUp => followUp.id !== followUpId)
            );
            handleFollowUpUpdate(response);
        } catch (error) {
            console.error('Failed to fetch Followups:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchFollowUpByDate= async() =>{
        setFollowUps([]);
        try {
            setLoadFollowUp(true);
            setLoading(true)
            const token = JSON.parse(localStorage.getItem('user')).token;
            if (executiveId) {
                const data = await FollowUpService.getFollowUp(executiveId, followUpDate ,token);
                setFollowUps(data);
            }
        } catch (error) {
            console.error('Failed to fetch Followups:', error);
        } finally {
            setLoadFollowUp(false);
            setLoading(false);
        }
    };

    return (
        <Modal className='modal_style' show={show} onHide={handleClose} size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Today's Follow-Ups</Modal.Title>
            </Modal.Header>
            <Modal.Body className="follwup_body">

                {/* search by date */}
                <div className="row align-items-end mb-3">
                    <div className="col-md-8">
                        <label>Select Date</label>
                        <input
                        type="date"
                        value={followUpDate}
                        onChange={(e) => setFollowUpDate(e.target.value)}
                        className="form-control"
                        >
                        </input>
                    </div>
                    <div className="col-md-4">
                        <div className="notes_save_btn">
                            <Button className="btn primary mt-2 w-100" type="submit" onClick={fetchFollowUpByDate} disabled={loading}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="card-columns followup_column">
                    {loadFollowUp ? (
                       <p>Fetching Follow Ups...</p>
                    ) : followUps.length === 0 ? (
                        <p className="text-danger"><strong>No Follow Ups For {followUpDate || "Today"}</strong></p>
                    ) : (
                        followUps.map(followUp => (

                            <Card
                                key={followUp.id}
                                style={{
                                    backgroundColor: `${followUp.completed ? '#ddffdd' : '#ffe0e0'}`
                                }}
                            >
                                <Card.Body className="followup_msg_card px-2 py-2">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 followup_msg_info">
                                            <Card.Title><strong>Msg: </strong>{followUp.message}</Card.Title>
                                            <Card.Subtitle>
                                                <strong>{followUp.customeName}</strong>
                                            </Card.Subtitle>
                                            <div className="followup_msg_phon">
                                                <p><strong>Phone:</strong> {followUp.customerPhone} </p>
                                                <p><strong>Follow-up Date:</strong> <br />{new Date(followUp.followUpDate).toLocaleString()}</p>
                                            </div>

                                        </div>

                                        <div className="col-md-12 followup_btn d-flex mt-2">
                                            <Button className="w-50 modal_btn py-0"
                                                variant="success"
                                                onClick={() => handleComplete(followUp.id)}
                                                disabled={loading || followUp.completed}
                                            >
                                                Complete
                                            </Button>
                                            <Button className="w-50 py-0 ml-2"
                                                variant="danger"
                                                onClick={() => handleDelete(followUp.id)}
                                                disabled={loading}
                                            >
                                                Delete
                                            </Button>

                                        </div>
                                    </div>

                                </Card.Body>
                            </Card>

                        ))
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className="py-1 mt-2">
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewFollowUps;