import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';

const PlanModal = ({ show, handleClose, handleSubmit, plan, response }) => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (show) {
            if (plan) {
                // If editing, set form fields with plan data
                setName(plan.name || '');
                setPrice(plan.price || '');
                setDescription(plan.description || '');
                setFile(null); // Optionally reset file if not editing
            } else {
                // Reset form fields if adding
                setName('');
                setPrice('');
                setDescription('');
                setFile(null);
            }
        }
    }, [show, plan]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({ 
            name, 
            price, 
            file, 
            description, 
            id: plan?.id // Include ID if editing
        });
    };

    const handleCloseModal = () => {
        // Reset form fields when closing the modal
        setName('');
        setPrice('');
        setDescription('');
        setFile(null);
        handleClose();
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleCloseModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{plan ? 'Edit Plan' : 'Add Plan'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter price/month"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="file">
                        <Form.Label>File Upload</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="justify-content-end modal_footer mt-3"> 
                    <Button variant="primary modal_btn w-auto" type="submit" disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" />{' '}
                                {plan ? 'Saving...' : 'Adding...'}
                            </>
                        ) : (
                            plan ? 'Save Changes' : 'Add Plan'
                        )}
                    </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PlanModal;
