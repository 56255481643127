import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import LoginPage from './view/LoginPage';
import { AuthProvider } from './context/AuthContext';
import AdminDashboard from './view/AdminDashboard';
import ManagerDashboard from './view/ManagerDashboard';
import { LoadScript } from '@react-google-maps/api';
import SeoDashboard from './view/SeoDashboard';

const libraries = ['places'];

function App() {
  return (
    <div className="App">
      <LoadScript  googleMapsApiKey="AIzaSyBoi_cpgvQj217kDrakkTvyQ0KThIsPWG8" libraries={libraries} >
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<LoginPage/>} />
                    <Route path="/login" element={<LoginPage/>} />
                    <Route path="/admin-dashboard" element={<AdminDashboard/>} />
                    <Route path="/manager-dashboard" element={<ManagerDashboard/>} />
                    <Route path="/executive-dashboard" element={<ManagerDashboard/>} />
                    <Route path="/seo-dashboard" element={<SeoDashboard/>} />                          
                    <Route path="/unauthorised" element={<LoginPage/>} />
                </Routes>
            </Router>
        </AuthProvider>
      </LoadScript>
    </div>     
  );
}

export default App;
