import React from 'react';
import { Form } from 'react-bootstrap';

function SubcategoryCheckboxList({ subcategories, selectedSubcategories, setSelectedSubcategories }) {
    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        setSelectedSubcategories(prevSelected => 
            prevSelected.includes(value)
                ? prevSelected.filter(sub => sub !== value)
                : [...prevSelected, value]
        );
    };

    return (        
            <Form.Group controlId="subcategory">
                <Form.Label>Subcategories</Form.Label>
                <div className='new_ad_services'> 
                    {subcategories.map(subcategory => (
                        <Form.Check
                            key={subcategory.id}
                            type="checkbox"
                            id={`subcategory-${subcategory.id}`}
                            label={subcategory.name}
                            value={subcategory.id}
                            checked={selectedSubcategories.includes(subcategory.id.toString())}
                            onChange={handleCheckboxChange}
                        />
                    ))}
                </div>
            </Form.Group>
    );
}

export default SubcategoryCheckboxList;
