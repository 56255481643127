import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import logo from '../login-logo.png';
import BlogService from "../services/BlogService";
import PaginationComponent from "../component/Common/PaginationComponent";
import BlogModal from "../component/FormModal/BlogModal";

const SeoDashboard = () => {

    const { user, isLoggedIn, logout } = useContext(AuthContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0)
    const [size, setSize] = useState(15);

    const [showBlogModal, setShowBlogModal] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
        } else if (user) {
            const role = user.role.replace(/^\[|\]$/g, '');
            if (role !== 'ROLE_SEO_EXECUTIVE') {
                logout();
                navigate('/unauthorised');
            }
        }
    }, [isLoggedIn, user, navigate]);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    
    useEffect(() => {
        fetchBlogs();
    }, [currentPage]);

    const fetchBlogs = async () => {
        try {
            setLoading(true);
            const data = await BlogService.getAllBlogs(currentPage, size);
            setBlogs(data.content);
            setTotalElements(data.totalElements);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching Blogs:', error);
        }finally{
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this blog?")) {
            try {
                const token = user.token;
                await BlogService.deleteBlog(id, token);
                // Re-fetch blogs to update the list
                fetchBlogs();
            } catch (error) {
                console.error('Error deleting blog:', error);
            }
        }
    };

    const handleShowBlogModal = (blog) => {
        setSelectedBlog(blog);
        setShowBlogModal(true);
    };

    const handleCloseBlogModal = () => {
        setShowBlogModal(false);
        setSelectedBlog(null);
    };

    const handleBlogSubmit = async (added) => {
        if (added) {
            fetchBlogs();
        }
    };

    return(
        <>

        <section className="manage_dashoard_nav">
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <a className="navbar-brand">
                            <h3><img src={logo} className='img-fluid' />ORS_Blogs</h3>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <button onClick={() => handleShowBlogModal()} className="btn btn-link nav-link" ><i className="fa-solid fa-plus"></i> Create New Blog</button>
                                </li>
                            </ul>

                            <button className="btn btn-theme" onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </section>

        <section className="manage_dashoard_content pb-0">
            <div className="container">
            <div className="row">
                {loading ? (                    
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="ms-3">Loading, Please wait...</span>
                </div>
                ) : (
                    <div className='table_data_manage table-responsive'>
                       <div className='blog_table_data col-md-11 mx-auto'>
                        <h2>Total Blogs: {totalElements}</h2>
                        <table className="compact-table table-bordered table-hover w-100">
                            <thead className='table-light'>
                                <tr>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Created_Date</th> 
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {blogs.length > 0 ? (
                                    blogs.map(blog => (
                                    <tr key={blog.id}>
                                        <td>{blog.id}</td>
                                        <td>{blog.title}</td>
                                        <td>{new Date(blog.createdAt).toLocaleDateString()}</td>
                                        <td className='action_btn'>
                                        <div className="d-inline-flex">
                                            <button onClick={() => handleShowBlogModal(blog)} className="btn p-0 me-1"><i className="fa-solid fa-pen-to-square"></i></button>
                                            <button onClick={() => handleDelete(blog.id)} className="btn p-0"><i className="fa-regular fa-trash-can"></i></button>
                                        </div>
                                        </td>
                                    </tr>
                                ))
                                ) : (
                                    <tr>
                                        <td colSpan="12" className="text-center">Please Create New Blogs</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {/* Pagination controls (optional) */}
                        {totalPages > 1 && (
                            <PaginationComponent
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                            />
                        )}
                    </div>
                    </div>
                )}
            </div>
            </div>
        </section>

        <BlogModal
            show={showBlogModal}
            handleClose={handleCloseBlogModal}
            handleSubmit={handleBlogSubmit}
            blog={selectedBlog} // Pass the selected blog for editing
        />
        </>
    );
};

export default SeoDashboard;