import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';

const UpdatePasswordModal = ({ show, handleClose, handleSubmit, response }) => {
    const [crmUserId, setCrmUserId] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!show) {
            // Reset form fields when modal is closed
            setCrmUserId('');
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            setError('');
        }
    }, [show]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setError('New passwords do not match');
            return;
        }
        handleSubmit({ crmUserId, oldPassword, newPassword });
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="crmUserId">
                        <Form.Label>CRM User ID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter CRM User ID"
                            value={crmUserId}
                            onChange={(e) => setCrmUserId(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="oldPassword">
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter old password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="confirmNewPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    {error && <p className="text-danger">{error}</p>}
                    <Row className="justify-content-end modal_footer mt-3">
                    <Button variant="primary modal_btn w-auto" type="submit" disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" /> Updating...
                            </>
                        ) : (
                            'Update Password'
                        )}
                    </Button>
                    </Row> 
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdatePasswordModal;