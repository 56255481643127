import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import AdvertisementService from '../../services/AdvertisementService';
import BannerUploadService from '../../services/BannerUploadService';

const ImageUploadModal = ({ show, handleClose, advertisementId }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewSrc, setPreviewSrc] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);

        // Generate a preview of the selected image
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onUpload = async () => {
        setLoading(true);
        if (selectedFile) {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const token = storedUser?.token;
            try {
                if (advertisementId) {
                    // If advertisementId is present, upload the image for the advertisement
                    const result = await AdvertisementService.uploadImage(advertisementId, selectedFile, token);
                    alert(result);
                } else {
                    // If advertisementId is not present, it use to sale banner upload
                    const alternativeResult = await BannerUploadService.uploadBanner(selectedFile, token);
                    alert(alternativeResult);
                }
            } catch (error) {
                console.error('Error during the upload process:', error.message);
            } finally {
                setLoading(false);
                onClose();
            }
        }
    };


    const onClose = () => {
        setSelectedFile(null);
        setPreviewSrc(null);
        handleClose();
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={onClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Upload Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Select Image</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                    {previewSrc && (
                        <div className="mt-3">
                            <img
                                src={previewSrc}
                                alt="Preview"
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer className='mt-3'>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary modal_btn" onClick={onUpload} disabled={!selectedFile || loading}>
                    {loading ? (
                        <>
                            <Spinner animation="border" size="sm" /> Uploading...
                        </>
                    ) : (
                        'Upload'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageUploadModal;
