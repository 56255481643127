import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/customers";

const createCustomer = async (customerDTO) => {
  try {
    const response = await axios.post(API_URL+'/create', customerDTO);
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const updateCustomer = async (id, customerDTO, token) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, customerDTO, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const updatePassword = async (id, updatePasswordRequest) => {
  try {
    const response = await axios.patch(`${API_URL}/${id}/password`, updatePasswordRequest);
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const updateStatus = async (id, status, token) => {
  try {
    const response = await axios.patch(`${API_URL}/status`, null, { 
      params: { id, status},     
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const deleteCustomer = async (id, token) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getCustomerCities = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/cities`, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getCustomerById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getAllCustomers = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/all`, {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getAllCustomersPages = async (page, size, token) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        page: page,
        size: size,
      },
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getAllCustomersByStatus = async (status, page, size, token) => {
  try {
    const response = await axios.get(`${API_URL}/status/${status}`,  {
      params: {
        page: page,
        size: size,
      },
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getCustomersForExecutive = async (executiveId, status, page, size, token) => {
  try {
    const response = await axios.get(`${API_URL}/byExecutive/${executiveId}`,  {
      params: {
        status: status,
        page: page,
        size: size,
      },
      headers: {
          'Authorization': `Bearer ${token}`
      }
  });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getCustomersByCity = async (city) => {
  try {
    const response = await axios.get(`${API_URL}/byCity/${city}`);
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getCustomersByState = async (state) => {
  try {
    const response = await axios.get(`${API_URL}/byState/${state}`);
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const getCustomersByCountry = async (country) => {
  try {
    const response = await axios.get(`${API_URL}/byCountry/${country}`);
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const updateExecutive = async (customerId, executiveId, token) => {
  try {
    const response = await axios.post(`${API_URL}/updateExecutive`, null, {
      params:{
        customerId: customerId,
        executiveId: executiveId
      },
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const bulkCreateCustomers = async (file, token) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${API_URL}/bulk-create`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

const searchCustomers = async (searchBy, searchValue, page, size, token) => {
  try {
    const response = await axios.get(`${API_URL}/search`, {
      params: {
        searchBy,
        searchValue,
        page,
        size,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleErrorResponse(error);
    throw error; 
  }
};

const handleErrorResponse = (error) => {
  if (error.response) {
    // Request made and server responded
    throw new Error(error.response.data);
  } else if (error.request) {
    // Request made but no response received
    throw new Error('No response from server');
  } else {
    // Something happened in setting up the request
    throw new Error(error.message);
  }
};

const CustomerService = {
  createCustomer,
  updateCustomer,
  updatePassword,
  updateStatus,
  deleteCustomer,
  getCustomerCities,
  getCustomerById,
  getAllCustomers,
  getAllCustomersPages,
  getAllCustomersByStatus,
  getCustomersByCity,
  getCustomersByState,
  getCustomersByCountry,
  getCustomersForExecutive,
  updateExecutive,
  bulkCreateCustomers,
  searchCustomers
};

export default CustomerService