import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';

const CrmUserModal = ({ show, handleClose, handleSubmit, user, response}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [designation, setDesignation] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (show) {
            if (user) {
                // If editing, set form fields with user data
                setName(user.name || '');
                setEmail(user.email || '');
                setPhone(user.phone || '');
                setDesignation(user.designation || '');
                setRole(user.role || '');
                setPassword(''); // Optionally reset password if not editing
            } else {
                // Reset form fields if adding
                setName('');
                setEmail('');
                setPhone('');
                setDesignation('');
                setPassword('');
                setRole('');
            }
        }
    }, [show, user]);

    const onSubmit = (e) => {
        e.preventDefault();
        // If user is provided, it's an edit operation, otherwise it's an add operation
        handleSubmit({
            name,
            email,
            phone,
            designation,
            role,
            password,
            id: user?.id // Include ID if editing
        });
    };

    const handleCloseModal = () => {
        // Reset form fields when closing the modal
        setName('');
        setEmail('');
        setPhone('');
        setDesignation('');
        setRole('');
        setPassword('');
        handleClose();
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleCloseModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{user ? 'Edit CRM User' : 'Add CRM User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="designation">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter designation"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="role">
                        <Form.Label>Role</Form.Label>
                        <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="">Select role</option>
                            <option value="ADMIN">Admin</option>
                            <option value="SALES_MANAGER">Sales Manager</option>
                            <option value="SALES_EXECUTIVE">Sales Executive</option>
                            <option value="SEO_EXECUTIVE">SEO Executive</option>
                        </Form.Control>
                    </Form.Group>
                    {!user && (
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                    )}

                    <Row className="justify-content-end modal_footer mt-3"> 
                    <Button variant="primary modal_btn w-auto" type="submit" disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" />{' '}
                                {user ? 'Saving...' : 'Adding...'}
                            </>
                        ) : (
                            user ? 'Save Changes' : 'Add User'
                        )}
                    </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CrmUserModal;
