import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/blog";

const createBlog = async (formData, token) => {
    try {
        const response = await axios.post(API_URL + '/create', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to create blog');
    }
};

const updateBlog = async (id, formData, token) => {
    try {
        const response = await axios.put(API_URL + `/update/${id}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to update blog');
    }
};

const deleteBlog = async (id, token) => {
    try {
        const response = await axios.delete(API_URL + `/delete/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delete blog');
    }
};

const getAllBlogs = async (page = 0, size = 10) => {
    try {
        const response = await axios.get(API_URL, {
            params: { page, size }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blogs');
    }
};

const getBlogById = async (id) => {
    try {
        const response = await axios.get(API_URL + `/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to fetch blog');
    }
};


const BlogService = {
    createBlog,
    updateBlog,
    deleteBlog,
    getAllBlogs,
    getBlogById
};

export default BlogService;