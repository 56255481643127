import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, ListGroup, Spinner } from 'react-bootstrap';
import LocationService from '../../services/LocationService';

const CustomerModal = ({ show, handleClose, handleSubmit, customer, executiveName, response }) => {
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [primaryPhone, setPrimaryPhone] = useState('');
    const [secondaryPhone, setSecondaryPhone] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [verified, setVerified] = useState(false);
    const [source, setSource] = useState('');

    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        if (show) {
            if (customer) {
                // If editing, set form fields with customer data
                setCompanyName(customer.companyName || '');
                setAddress(customer.address || '');
                setPrimaryPhone(customer.primaryPhone || '');
                setSecondaryPhone(customer.secondaryPhone || '');
                setCity(customer.city || '');
                setState(customer.state || '');
                setCountry(customer.country || '');
                setEmail(customer.email || '');
                setWebsite(customer.website || '');
                setVerified(customer.verified || false);
                setSource(customer.source || '');
            } else {
                // Reset form fields if adding
                setCompanyName('');
                setAddress('');
                setPrimaryPhone('');
                setSecondaryPhone('');
                setCity('');
                setState('');
                setCountry('');
                setEmail('');
                setWebsite('');
                setVerified(false);
                setSource('');
            }
        }
    }, [show, customer]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({
            companyName,
            address,
            primaryPhone,
            secondaryPhone,
            city,
            state,
            country,
            email,
            website,
            verified,
            source,
            executiveName: executiveName,
            id: customer?.id // Include ID if editing
        });
    };

    const handleCloseModal = () => {
        // Reset form fields when closing the modal
        setCompanyName('');
        setAddress('');
        setPrimaryPhone('');
        setSecondaryPhone('');
        setCity('');
        setState('');
        setCountry('');
        setEmail('');
        setWebsite('');
        setVerified(false);
        setSource('');
        handleClose();
    };

    const onCityChange = async (e) => {
        const newCity = e.target.value;
        setCity(newCity);

        if (newCity.length >= 3) {
            try {
                const response = await LocationService.getLocationByName(newCity);
                setLocationSuggestions(response);
                setShowSuggestions(true);
            } catch (error) {
                console.error('Error fetching location suggestions:', error);
            }
        } else {
            setShowSuggestions(false);
        }
    };

    const handleLocationSelect = (selectedLocation) => {
        setCity(selectedLocation.city.name);
        setState(selectedLocation.state.name);
        setCountry(selectedLocation.country.name);
        setShowSuggestions(false);
    };

    return (
        <Modal className='modal_style' show={show} onHide={handleCloseModal} size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{customer ? 'Edit Customer' : 'Add Customer'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="companyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter company name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        
                        <Col md={4}>
                            <Form.Group controlId="primaryPhone">
                                <Form.Label>Primary Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter number"
                                    value={primaryPhone}
                                    onChange={(e) => setPrimaryPhone(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Form.Group controlId="secondaryPhone">
                                <Form.Label>Secondary Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter number"
                                    value={secondaryPhone}
                                    onChange={(e) => setSecondaryPhone(e.target.value)}
                                />
                            </Form.Group>
                        </Col> 

                    </Row>
                    <Row className="mt-2">
                    <Col md={4}>
                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter local address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        
                        <Col md={4}>
                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter city"
                                    value={city}
                                    onChange={onCityChange}
                                    required
                                />
                                {showSuggestions && (
                                    <ListGroup className="position-absolute w-90" style={{ zIndex: 1 }}>
                                        {locationSuggestions.map((location, index) => (
                                            <ListGroup.Item key={index} action onClick={() => handleLocationSelect(location)}>
                                                {location.location}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="state">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={4}>
                            <Form.Group controlId="country">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter country"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="website">
                                <Form.Label>Website</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter website"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-2 align-items-end">
                        <Col md={6}>
                            <Form.Group controlId="source">
                                <Form.Label>Source</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter source"
                                    value={source}
                                    onChange={(e) => setSource(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group controlId="verified">
                                <Form.Check
                                    type="checkbox"
                                    checked={verified}
                                    onChange={(e) => setVerified(e.target.checked)}
                                    label="Verified"
                                />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row className="justify-content-end modal_footer mt-3"> 
                        <Button variant="primary w-auto modal_btn" type="submit" disabled={response}>
                            {response ? (
                                <>
                                    <Spinner animation="border" size="sm" /> {customer ? 'Saving Changes...' : 'Adding Customer...'}
                                </>
                            ) : (
                                customer ? 'Save Changes' : 'Add Customer'
                            )}
                        </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CustomerModal;
