import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseUrl+"api/customer-notes";

const createNote = async (noteDTO, token) => {
    try {
        const response = await axios.post(API_URL, noteDTO, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
       throw new Error(error.response ? error.response.data : 'Failed to create customer note');
    }
};

const deleteNote = async (id, token) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to delete customer note');
    }
};

const getNoteById = async (id, token) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get customer note by ID');
    }
};

const getNotesByCustomerId = async (customerId, token) => {
    try {
        const response = await axios.get(`${API_URL}/customer/${customerId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get customer notes by customer ID');
    }
};

const customerNoteService = {
    createNote,
    deleteNote,
    getNoteById,
    getNotesByCustomerId
};

export default customerNoteService;