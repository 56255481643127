import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Form, Spinner } from 'react-bootstrap';
import CategoryService from '../../services/CategoryService';

const SubcategoryModal = ({ show, handleClose, handleSubmit, subcategory, response }) => {
    const [name, setName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (show) {
            const fetchCategories = async () => {
                try {
                    const storedUser = JSON.parse(localStorage.getItem('user'));
                    const response = await CategoryService.getAllCategories(storedUser.token);
                    setCategories(response);
                } catch (err) {
                    setError('Failed to fetch categories');
                } finally {
                    setLoading(false);
                }
            };
            fetchCategories();
        }
    }, [show]);

    useEffect(() => {
        if (subcategory) {
            setName(subcategory.name || '');
            setCategoryId(subcategory.categoryId || '');
        } else {
            setName('');
            setCategoryId('');
        }
    }, [subcategory]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({ 
            name,
            categoryId,
            id: subcategory?.id // Include ID if editing
        });
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setName('');
        setCategoryId('');
        handleClose();
    };

    return (
        <Modal className='modal_style admin_modal_style' show={show} onHide={handleCloseModal}> 
            <Modal.Header closeButton>
                <Modal.Title>{subcategory ? 'Edit Sub-category' : 'Add Subcategory'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <p>Loading categories...</p>}
                {error && <p className="text-danger">{error}</p>}
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="category">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            as="select"
                            value={categoryId}
                            onChange={(e) => setCategoryId(e.target.value)}
                        >
                            <option value="">Select category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="name">
                        <Form.Label>Subcategory Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter subcategory name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="justify-content-end modal_footer mt-3"> 
                    <Button variant="primary modal_btn w-auto" type="submit" disabled={response}>
                        {response ? (
                            <>
                                <Spinner animation="border" size="sm" />{' '}
                                {subcategory ? 'Saving...' : 'Adding...'}
                            </>
                        ) : (
                            subcategory ? 'Save Changes' : 'Add Subcategory'
                        )}
                    </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SubcategoryModal;
