import React from 'react';
import imageService from '../../services/ImageServie';

const Table = ({ data, columnsToShow, onDelete, onEdit }) => {
    const handleEdit = (item) => {
        onEdit(item);
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            onDelete(id);
        }
    }; 

    return (
        <div className='table_data_manage table-responsive'>
        <table className="compact-table table-bordered table-hover w-100">
            <thead className='table-light'>
                <tr>
                    {columnsToShow.map(col => (
                        <th key={col}>{col}</th>
                    ))}
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {data.length > 0 ? (
                    data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columnsToShow.map(col => (
                                <td key={col}>
                                    {/* Handle boolean values or other types if needed */}
                                    {typeof row[col] === 'boolean' ? (row[col] ? 'Yes' : 'No') : row[col] !== null ? ( 
                                        col === 'imageName' ? (
                                            <a href={`${imageService.getPlanImage}${row[col]}`} target="_blank" rel="noopener noreferrer">
                                                {row[col]}
                                            </a>
                                        ) : ( row[col] ) ) : 'N/A'}
                                </td>
                            ))}
                            <td className='action_btn'>  
                                <div className="d-inline-flex">
                                    <button
                                        className="btn p-0 me-1"
                                        onClick={() => handleEdit(row)}
                                    >
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button
                                        className="btn p-0"
                                        onClick={() => handleDelete(row.id)}
                                    >
                                        <i className="fa-regular fa-trash-can"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={columnsToShow.length + 1}>No data available</td>
                    </tr>
                )}
            </tbody>
        </table>
        </div>
    );
};

export default Table;
